import axiosInstance from '../utils/axiosConfig';
import {Server} from "../models/server";
import {BASE_URL} from "../Constant";

const API_BASE_URL =  '/servers'; // Replace with your actual API endpoint

export const getServers = () => axiosInstance.get<Server[]>(API_BASE_URL);

export const createServer = (domain: Server) => axiosInstance.post<Server>(API_BASE_URL, domain);

export const updateServer = (id: number, domain: Server) => axiosInstance.put<Server>(`${API_BASE_URL}/${id}`, domain);

export const deleteServer = (id: number) => axiosInstance.delete(`${API_BASE_URL}/${id}`);

export const generateConfig = (id: number) => axiosInstance.get<string>(`${BASE_URL}/config/${id}`);



export const updateServerToInbounds = async (serverId: number, inboundIds: number[]): Promise<InboundsUpdateResponse> => {
    try {
        const response = await axiosInstance.post<InboundsUpdateResponse>(`${API_BASE_URL}/${serverId}/inbounds`, { inboundIds });
        return response.data;
    } catch (error) {
        console.error('Error adding inbound to servers:', error);
        throw error;
    }
};

export const getSelectedInboundIds = async  (inboundId: number) => {
    try {
        const response = await axiosInstance.get(`${API_BASE_URL}/${inboundId}/selected-inbounds`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch selected servers:', error);
    }
};

interface InboundUpdateResult {
    inboundId: number;
    status: string;
    message: string;
}

interface InboundsUpdateResponse {
    results: InboundUpdateResult[];
}