import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TablePagination,
    Switch,
    FormControlLabel,
    Box,
    LinearProgress,
    Typography,
    Checkbox
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Client } from "../models/client";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import {Domain} from "../models/domain";

interface ClientListProps {
    clients: Client[];
    domains: Domain[];
    onEdit: (client: Client) => void;
    onDelete: (id: number) => void;
    onRenew: (client: Client) => void;
}

const ClientList: React.FC<ClientListProps> = ({ clients, domains, onEdit, onDelete, onRenew }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterActive, setFilterActive] = useState<boolean>(false);
    const [filterInactive, setFilterInactive] = useState<boolean>(false);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, clients.length - page * rowsPerPage);

    const getCopyText = (subId: string, dataCap: number, domainId: number| undefined , expiryTime: string | undefined) => {
        const domain = domains.find(d => d.id === domainId);
        const link = `https://royalmax.site/?domain=${domain?.root}&subId=${subId}`;
        return `به خانواده MAX VPN خوش آمدید\nلطفا روی لینک زیر کلیک کنید تا به صفحه اشتراک و راهنمای نصب وارد شوید\n${link}\n\nحجم: GB ${dataCap} \nتاریخ اتمام: ${expiryTime}`;
    };

    const bytesToGB = (bytes: number) => {
        return (bytes / (1024 ** 3)).toFixed(2);
    };

    const sortedClients = [...clients].sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    const filteredClients = filterActive
        ? sortedClients.filter(client => (client.up + client.down) !== 0)
        : filterInactive ? sortedClients.filter(client => (client.up + client.down) === 0) : sortedClients;

    return (
        <Paper>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>User Name</TableCell>
                            <TableCell>Contact</TableCell>
                            <TableCell>Subscription</TableCell>
                            <TableCell>IS</TableCell>
                            <TableCell>
                                <FormControlLabel
                                    control={<Checkbox checked={filterActive} onChange={() => setFilterActive(!filterActive)} />}
                                    label="Active"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={filterInactive} onChange={() => setFilterInactive(!filterActive)} />}
                                    label="Inactive"
                                />
                            </TableCell>
                            <TableCell>Expiry Time</TableCell>
                            <TableCell>Actions</TableCell>
                            <TableCell>Enable</TableCell>
                            <TableCell>Sub Link</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((client) => (
                            <TableRow key={client.id}>
                                <TableCell sx={{
                                    maxWidth: '60px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    m: 2,
                                }}>
                                    {client.id}</TableCell>
                                <TableCell>{client.email}</TableCell>
                                <TableCell>{client.contact}</TableCell>
                                <TableCell>{client.subId}</TableCell>
                                <TableCell>{client.inboundServerCount}</TableCell>
                                <TableCell>
                                    <Box sx={{ width: 150 }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={(client.up + client.down) / (client.dataCap * 1024 ** 3) * 100}
                                            sx={{ mb: 1 }}
                                        />
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="caption">
                                                {`${bytesToGB(client.up + client.down)} GB`}
                                            </Typography>
                                            <Typography variant="caption">
                                                {`${client.dataCap} GB`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell>{client.expiryTime}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onRenew(client)}> {/* Add onClick handler */}
                                        <AutorenewIcon />
                                    </IconButton>
                                    <IconButton onClick={() => onEdit(client)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => onDelete(client.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <FormControlLabel
                                        control={<Switch checked={client.enable} name="enable" />}
                                        label=""
                                    />
                                </TableCell>
                                <TableCell>
                                    <CopyToClipboard text={getCopyText(client.subId, client.dataCap, client.domainId , client.expiryTime)}>
                                        <IconButton color="primary">
                                            <CardMembershipIcon fontSize={"large"} />
                                        </IconButton>
                                    </CopyToClipboard>
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={11} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredClients.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default ClientList;
