import axiosInstance from '../utils/axiosConfig';
import {Domain} from "../models/domain";

const API_URL =  '/domains'; // Replace with your actual API endpoint

export const getDomains = () => axiosInstance.get<Domain[]>(API_URL);

export const getSubscriptionDomains = () => axiosInstance.get<Domain[]>(`${API_URL}/subscription`);

export const createDomain = (domain: Domain) => axiosInstance.post<Domain>(API_URL, domain);

export const updateDomain = (id: number, domain: Domain) => axiosInstance.put<Domain>(`${API_URL}/${id}`, domain);

export const deleteDomain = (id: number) => axiosInstance.delete(`${API_URL}/${id}`);
