import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TablePagination,
    Switch, FormControlLabel, Button, Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GradingIcon from '@mui/icons-material/Grading';
import { Country, countryFlagMap } from '../models/Country';
import {Server} from "../models/server";
import {Link} from "react-router-dom";
import { generateConfig } from "../services/serverService";

interface ServerListProps {
    servers: Server[];
    onEdit: (server: Server) => void;
    onDelete: (id: number) => void;
}

const ServerList: React.FC<ServerListProps> =
    ({ servers, onEdit, onDelete }) => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(5);
        const [results, setResults] = useState<{ [key: number]: string }>({});

        const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const handleGenerateConfig = async (serverId: number) => {
            try {
                const response = await generateConfig(serverId);
                setResults(prev => ({ ...prev, [serverId]: response.data }));
            } catch (error: any) {
                let errorMessage = 'Error generating config';
                if (error.response && error.response.data) {
                    errorMessage = error.response.data;
                } else if (error.message) {
                    errorMessage = error.message;
                }
                setResults(prev => ({ ...prev, [serverId]: errorMessage }));
            }
        };

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, servers.length - page * rowsPerPage);

        return (
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Country</TableCell>
                                <TableCell>IP</TableCell>
                                <TableCell>Tag</TableCell>
                                <TableCell>Enabled</TableCell>
                                <TableCell>Inbounds</TableCell>
                                <TableCell>Actions</TableCell>
                                {/*<TableCell>Result</TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {servers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((server) => (
                                <TableRow key={server.id}>
                                    <TableCell>{server.id}</TableCell>
                                    <TableCell>
                                        {countryFlagMap[server.country as Country]} {server.country}
                                    </TableCell>
                                    <TableCell>{server.ip}</TableCell>
                                    <TableCell>{server.tag}</TableCell>
                                    <TableCell><FormControlLabel
                                        control={<Switch checked={server.enable}/>}
                                        label={""}/>
                                    </TableCell>

                                    <TableCell>
                                        <Link to={`/manage-inbounds/${server.id}`}>
                                            <Button variant="text" color="primary" className="circle-button">
                                                {server.inboundCount}
                                            </Button>
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => onEdit(server)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => onDelete(server.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        {/*<IconButton onClick={() => handleGenerateConfig(server.id)}>*/}
                                        {/*    <GradingIcon />*/}
                                        {/*</IconButton>*/}
                                    </TableCell>
                                    {/*<TableCell>*/}
                                    {/*    <Typography variant="body2">*/}
                                    {/*        {results[server.id]}*/}
                                    {/*    </Typography>*/}
                                    {/*</TableCell>*/}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={servers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        );
    };

export default ServerList;
