import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TablePagination,
    Switch,
    FormControlLabel,
    Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {InboundDTO} from "../models/dto/Inbound";

interface ServerListProps {
    inbounds: InboundDTO[];
    onEdit: (inboundDTO: InboundDTO) => void;
    onDelete: (id: number) => void;
}

const ServerList: React.FC<ServerListProps> =
    ({ inbounds, onEdit, onDelete }) => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(5);
        const [selectedInboundId, setSelectedInboundId] = useState<number | null>(null);




        const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };


        const handleSaveServers = async (serverIds: number[]) => {
            if (selectedInboundId !== null) {
                // Call API to update the servers for the inbound
                // Replace 'API_URL' with your actual API URL
            }
        };
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, inbounds.length - page * rowsPerPage);

        return (
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Protocol</TableCell>
                                <TableCell>Enabled</TableCell>
                                <TableCell>Tag</TableCell>
                                <TableCell>Servers</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {inbounds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((inbound) => (
                                <TableRow key={inbound.id} >
                                    <TableCell>{inbound.id}</TableCell>
                                    <TableCell>{inbound.protocol}</TableCell>
                                    <TableCell><FormControlLabel
                                        control={<Switch checked={inbound.enable}/>}
                                        label={""}/></TableCell>
                                    <TableCell>{inbound.tag}</TableCell>
                                    <TableCell>
                                        <Link to={`/manage-servers/${inbound.id}`}>
                                            <Button variant="text" color="primary" className="circle-button">
                                                {inbound.serverCount}
                                            </Button>
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => onEdit(inbound)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => onDelete(inbound.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={inbounds.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        );
    };

export default ServerList;
