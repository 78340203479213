import React from 'react';
import { TextField, Box } from '@mui/material';
import { Header } from '../../models/Inbound/StreamSetting/network/WsSettings';
import {WsSettings} from "../../models/Inbound/StreamSetting/network/WsSettings"; // Adjust the import path as needed

interface WsSettingsFormProps {
    wsSettings: WsSettings;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const WsSettingsForm: React.FC<WsSettingsFormProps> = ({ wsSettings, handleChange }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <TextField
                label="Path"
                name="streamSettings.wsSettings.path"
                value={wsSettings.path}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="Host"
                name="streamSettings.wsSettings.host"
                value={wsSettings.host}
                onChange={handleChange}
                fullWidth
            />
            {wsSettings.headers.map((header: Header, index: number) => (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <TextField
                        label={`Header ${index + 1} Name`}
                        name={`streamSettings.wsSettings.headers[${index}].name`}
                        value={header.name}
                        onChange={handleChange}
                        fullWidth
                    />
                    <TextField
                        label={`Header ${index + 1} Value`}
                        name={`streamSettings.wsSettings.headers[${index}].value`}
                        value={header.value}
                        onChange={handleChange}
                        fullWidth
                    />
                </Box>
            ))}
        </Box>
    );
};

export default WsSettingsForm;
