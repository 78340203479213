import React from 'react';
import { TextField, Box } from '@mui/material';

import {QuicSettings} from "../../models/Inbound/StreamSetting/network/QuicSettings"; // Adjust the import path as needed

interface QuicSettingsFormProps {
    quicSettings: QuicSettings;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const QuicSettingsForm: React.FC<QuicSettingsFormProps> = ({ quicSettings, handleChange }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
                label="Security"
                name="streamSettings.quicSettings.security"
                value={quicSettings.security}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="Key"
                name="streamSettings.quicSettings.key"
                value={quicSettings.key}
                onChange={handleChange}
                fullWidth
            />
        </Box>
    );
};

export default QuicSettingsForm;
