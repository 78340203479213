import React, { useContext } from 'react';
import {
    List,
    ListItemIcon,
    ListItemText,
    Divider,
    ListItemButton,
    Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import StorageIcon from '@mui/icons-material/Storage';
import InputIcon from '@mui/icons-material/Input';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import { AuthContext } from '../AuthContext';
import LockIcon from '@mui/icons-material/Lock';

const SideMenu: React.FC = () => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        authContext?.logout();
        navigate('/login');
    };

    return (
        <div style={{ width: 240, borderRight: '1px solid #ddd' }}>
            <div style={{ textAlign: 'center' }}>
                <img src="/logo512.png" alt="Logo" style={{ width: '60%', height: 'auto' }} />
                <Typography variant="h5" component="div" style={{ marginTop: -35 }}>
                    Max VPN
                </Typography>
            </div>
            <List>
                <ListItemButton component={Link} to="/dashboard">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
                {/*<ListItemButton component={Link} to="/client">*/}
                {/*    <ListItemIcon>*/}
                {/*        <PersonIcon />*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary="Client" />*/}
                {/*</ListItemButton>*/}
                <ListItemButton component={Link} to="/clients">
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Client Management" />
                </ListItemButton>
                <ListItemButton component={Link} to="/domains">
                    <ListItemIcon>
                        <PublicIcon />
                    </ListItemIcon>
                    <ListItemText primary="Domain Management" />
                </ListItemButton>
                <ListItemButton component={Link} to="/servers">
                    <ListItemIcon>
                        <StorageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Server Management" />
                </ListItemButton>
                <ListItemButton component={Link} to="/inbound">
                    <ListItemIcon>
                        <InputIcon />
                    </ListItemIcon>
                    <ListItemText primary="Inbound Management" />
                </ListItemButton>
            </List>
            <Divider />
            <List>
                <ListItemButton onClick={handleLogout}>
                    <ListItemIcon>
                        <LockIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
        </div>
    );
};

export default SideMenu;
