import React from 'react';
import {TextField, Box, Switch, FormControlLabel} from '@mui/material';

import {TcpSettings} from "../../models/Inbound/StreamSetting/network/TcpSettings"; // Adjust the import path as needed

interface TcpSettingsFormProps {
    tcpSettings: TcpSettings;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const TcpSettingsForm: React.FC<TcpSettingsFormProps> = ({ tcpSettings, handleChange }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={tcpSettings.acceptProxyProtocol}
                        onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'streamSettings.tcpSettings.acceptProxyProtocol', value: e.target.checked } })}
                        name="streamSettings.tcpSettings.acceptProxyProtocol"
                    />
                }
                label="Proxy Protocol"
            />

            {/*<FormControlLabel*/}
            {/*    control={*/}
            {/*        <Switch*/}
            {/*            checked={tcpSettings.type === 'http'}*/}
            {/*            onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'streamSettings.tcpSettings.type', value: e.target.checked? 'http' : 'none' } })}*/}
            {/*            name="streamSettings.tcpSettings.type"*/}
            {/*        />*/}
            {/*    }*/}
            {/*    label="HTTP Obfuscation"*/}
            {/*/>*/}


            {/*<TextField*/}
            {/*    label="Request Version"*/}
            {/*    name="streamSettings.tcpSettings.request.version"*/}
            {/*    value={tcpSettings.request.version}*/}
            {/*    onChange={handleChange}*/}
            {/*    fullWidth*/}
            {/*/>*/}
            {/*<TextField*/}
            {/*    label="Request Method"*/}
            {/*    name="streamSettings.tcpSettings.request.method"*/}
            {/*    value={tcpSettings.request.method}*/}
            {/*    onChange={handleChange}*/}
            {/*    fullWidth*/}
            {/*/>*/}
            {/*<TextField*/}
            {/*    label="Request Path"*/}
            {/*    name="streamSettings.tcpSettings.request.path"*/}
            {/*    value={tcpSettings.request.path.join(',')}*/}
            {/*    onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'streamSettings.tcpSettings.request.path', value: e.target.value.split(',') } })}*/}
            {/*    fullWidth*/}
            {/*/>*/}
            {/*/!* Add headers as needed *!/*/}

            {/*<TextField*/}
            {/*    label="Response Version"*/}
            {/*    name="streamSettings.tcpSettings.response.version"*/}
            {/*    value={tcpSettings.response.version}*/}
            {/*    onChange={handleChange}*/}
            {/*    fullWidth*/}
            {/*/>*/}
            {/*<TextField*/}
            {/*    label="Response Status"*/}
            {/*    name="streamSettings.tcpSettings.response.status"*/}
            {/*    value={tcpSettings.response.status}*/}
            {/*    onChange={handleChange}*/}
            {/*    fullWidth*/}
            {/*/>*/}
            {/*<TextField*/}
            {/*    label="Response Reason"*/}
            {/*    name="streamSettings.tcpSettings.response.reason"*/}
            {/*    value={tcpSettings.response.reason}*/}
            {/*    onChange={handleChange}*/}
            {/*    fullWidth*/}
            {/*/>*/}
            {/* Add headers as needed */}
        </Box>
    );
};

export default TcpSettingsForm;
