// Converter function to create InboundDTO from Inbound
import {InboundDTO} from "../dto/Inbound";
import {Inbound, InboundHandler, Settings, StreamSetting} from "./Inbound";
import {
    ProtocolType,
    SecurityType,
    TransmissionType,
} from "./Consts";


// Helper function to get the relevant protocol settings
function getProtocolSettings(protocol: ProtocolType, settings: Settings): any {
    switch (protocol) {
        case ProtocolType.SHADOWSOCKS:
            return { shadowsocks: settings.shadowsocks };
        // case ProtocolType.DOKODEMO:
        //     return { dokodemo: settings.dokodemo };
        // case ProtocolType.SOCKS:
        //     return { socks: settings.socks };
        // case ProtocolType.HTTP:
        //     return { http: settings.http };
        // case ProtocolType.WIREGUARD:
        //     return { wireguard: settings.wireguard };
        default:
            return { };
    }
}

// Helper function to get the relevant stream settings
function getStreamSettings(streamSettings: StreamSetting): any {
    const settings: any = {
        network: streamSettings.network,
        security: streamSettings.security,
        tlsSettings: streamSettings.security === SecurityType.Tls ? streamSettings.tlsSettings : undefined,
        xtlsSettings: streamSettings.security === SecurityType.Xtls ? streamSettings.xtlsSettings : undefined,
        realitySettings: streamSettings.security === SecurityType.Reality ? streamSettings.realitySettings : undefined,

        tcpSettings: streamSettings.network === TransmissionType.Tcp ? streamSettings.tcpSettings : undefined,
        kcpSettings: streamSettings.network === TransmissionType.Kcp ? streamSettings.kcpSettings : undefined,
        wsSettings: streamSettings.network === TransmissionType.Ws ? streamSettings.wsSettings : undefined,
        httpSettings: streamSettings.network === TransmissionType.HTTP ? streamSettings.httpSettings : undefined,
        quicSettings: streamSettings.network === TransmissionType.Quic ? streamSettings.quicSettings : undefined,
        grpcSettings: streamSettings.network === TransmissionType.Grpc ? streamSettings.grpcSettings : undefined,
        httpupgradeSettings: streamSettings.network === TransmissionType.HTTPUpgrade ? streamSettings.httpUpgradeSettings : undefined,

        sockopt: streamSettings.sockopt?.enable  ===  true ? streamSettings.sockopt : undefined ,
    };
    return settings;
}

export function convertInboundToDTO(inbound: Inbound): InboundDTO {

    // Convert the selected protocol and stream settings to JSON strings
    const settings = JSON.stringify(getProtocolSettings(inbound.protocol, inbound.settings));
    const streamSettings = InboundHandler.canEnableStream(inbound.protocol) ? JSON.stringify(getStreamSettings(inbound.streamSettings)) : "{}";
    const sniffing = JSON.stringify(inbound.sniffing);


    // Create and return the InboundDTO object
    return {
        id: inbound.id,
        up: 0, // Assuming default value for up
        down: 0, // Assuming default value for down
        total: 0, // Assuming default value for total
        remark: inbound.remark,
        enable: inbound.enable,
        listen: inbound.listen,
        port: inbound.port,
        tag: inbound.tag,
        protocol: inbound.protocol,
        settings: settings,
        streamSettings: streamSettings,
        sniffing: sniffing,
        serverCount: 0,
        clientCount: 0,
    };
}
