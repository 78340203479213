
export enum Country {
    AU = "AU",
    DE = "DE",
    FI = "FI",
    FR = "FR",
    IR = "IR",
    NL = "NL",
    US = "US",
    UK = "UK",
    TR = "TR" // Add Turkey to the enum
}

export const countryFlagMap: { [key in Country]: string } = {
    [Country.AU]: '🇦🇺',
    [Country.DE]: '🇩🇪',
    [Country.FI]: '🇫🇮',
    [Country.FR]: '🇫🇷',
    [Country.IR]: '🇮🇷',
    [Country.NL]: '🇳🇱',
    [Country.US]: '🇺🇸',
    [Country.UK]: '🇬🇧',
    [Country.TR]: '🇹🇷' // Add Turkey flag to the map
};

// Define a mapping from numeric values to country names
export const countryNameMap: { [key: number]: string } = {
    1: Country.US,
    2: Country.DE,
    3: Country.FI,
    4: Country.FR,
    5: Country.IR,
    6: Country.NL,
    7: Country.AU,
    8: Country.UK,
    9: Country.TR,
    // Add more mappings as needed
};

