// src/pages/InboundManagement.tsx

import React, { useState, useEffect } from 'react';
import InboundList from '../components/InboundList';
import InboundService from '../services/InboundService';
import {InboundDTO} from "../models/dto/Inbound";
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material';
import InboundForm from "../form/InboundForm";

import {convertDTOToInbound} from "../models/Inbound/ConvertDTOToInbound";

const InboundManagement: React.FC = () => {
    const [inbounds, setInbounds] = useState<InboundDTO[]>([]);
    const [filteredInbounds, setFilteredInbounds] = useState<InboundDTO[]>([]);
    const [currentInbound, setCurrentInbound] = useState<InboundDTO | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [inboundToDelete, setInboundToDelete] = useState<number | undefined>(undefined);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchInbounds();
    }, []);

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredInbounds(inbounds);
        } else {
            setFilteredInbounds(inbounds.filter(inbound => inbound.tag.toLowerCase().includes(searchTerm.toLowerCase())));
        }
    }, [searchTerm, inbounds]);

    const fetchInbounds = async () => {
        try {
            const response = await InboundService.getAll();
            setInbounds(response);
            setFilteredInbounds(response);
        } catch (error) {
            console.error('Failed to fetch inbounds:', error);
        }
    };

    const handleCreateOrUpdate = async (inbound: InboundDTO) => {
        try {
            if (inbound.id) {
                await InboundService.update(inbound);
            } else {
                await InboundService.create(inbound);
            }
            fetchInbounds();
            setCurrentInbound(undefined);
            setOpen(false);
        } catch (error) {
            console.error('Failed to create or update inbound:', error);
        }
    };

    const handleEdit = (inbound: InboundDTO) => {
        setCurrentInbound(inbound);
        setOpen(true);
    };

    const handleOpen = () => {
        setCurrentInbound(undefined);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteClick = (id: number) => {
        setInboundToDelete(id);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (inboundToDelete) {
            try {
                await InboundService.delete(inboundToDelete);
                fetchInbounds();
                setInboundToDelete(undefined);
                setDeleteDialogOpen(false);
            } catch (error) {
                console.error('Failed to delete inbound:', error);
            }
        }
    };

    const handleDeleteCancel = () => {
        setInboundToDelete(undefined);
        setDeleteDialogOpen(false);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Inbound Management
            </Typography>
            <Box mb={2} display="flex" justifyContent="space-between">
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    Add Inbound
                </Button>
            </Box>
            <InboundList inbounds={filteredInbounds} onEdit={handleEdit} onDelete={handleDeleteClick} />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentInbound ? 'Edit Inbound' : 'Add Inbound'}</DialogTitle>
                <DialogContent>
                    <InboundForm onSubmit={handleCreateOrUpdate} inboundProp={currentInbound ? convertDTOToInbound(currentInbound) : undefined} isEditing= {!!currentInbound} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this inbound?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default InboundManagement;
