export enum TLS_VERSION_OPTION {
    TLS10 = "1.0",
    TLS11 = "1.1",
    TLS12 = "1.2",
    TLS13 = "1.3",
}

export enum ALPN_OPTION {
    H3 = "h3",
    H2 = 'h2',
    HTTP1 = 'http/1.1',
}

export enum USAGE_OPTION {
    ENCIPHERMENT = 'encipherment',
    VERIFY = "verify",
    ISSUE = "issue",
}
export const SNIFFING_OPTION = {
    HTTP: "http",
    TLS: "tls",
    QUIC: "quic",
    FAKEDNS: "fakedns"
};

export enum DOMAIN_STRATEGY_OPTION {
    AS_IS = "AsIs",
    USE_IP = "UseIP",
    USE_IPV6V4 = "UseIPv6v4",
    USE_IPV6 = "UseIPv6",
    USE_IPV4V6 = "UseIPv4v6",
    USE_IPV4 = "UseIPv4",
    FORCE_IP = "ForceIP",
    FORCE_IPV6V4 = "ForceIPv6v4",
    FORCE_IPV6 = "ForceIPv6",
    FORCE_IPV4V6 = "ForceIPv4v6",
    FORCE_IPV4 = "ForceIPv4",
}

export enum TCP_CONGESTION_OPTION {
    BBR = "bbr",
    CUBIC = "cubic",
    RENO = "reno",
}

export enum SecurityType {
    None = 'none',
    Xtls = 'xtls',
    Tls = 'tls',
    Reality = 'reality'
}

export enum UTLS_FINGERPRINT {
    UTLS_CHROME = "chrome",
    UTLS_FIREFOX = "firefox",
    UTLS_SAFARI = "safari",
    UTLS_IOS = "ios",
    UTLS_ANDROID = "android",
    UTLS_EDGE = "edge",
    UTLS_360 = "360",
    UTLS_QQ = "qq",
    UTLS_RANDOM = "random",
    UTLS_RANDOMIZED = "randomized",
}

export enum TransmissionType {
    Tcp = 'Tcp',
    Kcp = 'Kcp',
    Ws = 'Ws',
    Quic = 'Quic',
    Grpc = 'Grpc',
    HTTP = 'HTTP',
    HTTPUpgrade = 'HTTPUpgrade'
}

export enum ProtocolType {
    VMESS = 'vmess',
    VLESS = 'vless',
    TROJAN = 'trojan',
    SHADOWSOCKS = 'shadowsocks',
    // DOKODEMO = 'dokodemo-door',
    // SOCKS = 'socks',
    // HTTP = 'http',
    // WIREGUARD = 'wireguard',
}

export enum SSMethod {
    AES_256_GCM = 'aes-256-gcm',
    AES_128_GCM = 'aes-128-gcm',
    CHACHA20_POLY1305 = 'chacha20-poly1305',
    CHACHA20_IETF_POLY1305 = 'chacha20-ietf-poly1305',
    XCHACHA20_POLY1305 = 'xchacha20-poly1305',
    XCHACHA20_IETF_POLY1305 = 'xchacha20-ietf-poly1305',
    BLAKE3_AES_128_GCM = '2022-blake3-aes-128-gcm',
    BLAKE3_AES_256_GCM = '2022-blake3-aes-256-gcm',
    BLAKE3_CHACHA20_POLY1305 = '2022-blake3-chacha20-poly1305',
}

export enum NetworkType{
    TCP_UDP = 'tcp,udp',
    TCP  = 'tcp',
    UDP = 'udp'
}