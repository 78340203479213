import React from 'react';
import { TextField, FormGroup, FormControlLabel, Switch, Typography, MenuItem, Grid } from '@mui/material';
import { TlsSettings} from "../../models/Inbound/StreamSetting/security/TlsSettings";
import {TLS_VERSION_OPTION} from "../../models/Inbound/Consts";

interface TlsSecurityFormProps {
    tlsSettings: TlsSettings;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const TlsSecurityForm: React.FC<TlsSecurityFormProps> = ({ tlsSettings, handleChange }) => {
    const handleTlsSettingsChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        handleChange(e);
    };

    return (
        <div >
            <Typography variant="h6"></Typography>
            <FormGroup>
                <TextField
                    label="Server Name"
                    name="streamSettings.tlsSettings.serverName"
                    value={tlsSettings.serverName}
                    onChange={handleTlsSettingsChange}
                    fullWidth
                    margin="normal"
                />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Minimum Version"
                            name="streamSettings.tlsSettings.minVersion"
                            select
                            value={tlsSettings.minVersion}
                            onChange={handleTlsSettingsChange}
                            fullWidth
                            margin="normal"
                        >
                            {Object.values(TLS_VERSION_OPTION).map(version => (
                                <MenuItem key={version} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6} >
                        <TextField
                            label="Maximum Version"
                            name="streamSettings.tlsSettings.maxVersion"
                            select
                            value={tlsSettings.maxVersion}
                            onChange={handleTlsSettingsChange}
                            fullWidth
                            margin="normal"
                        >
                            {Object.values(TLS_VERSION_OPTION).map(version => (
                                <MenuItem key={version} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <TextField
                    label="Cipher Suites"
                    name="streamSettings.tlsSettings.cipherSuites"
                    value={tlsSettings.cipherSuites}
                    onChange={handleTlsSettingsChange}
                    fullWidth
                    margin="normal"
                />
                <FormControlLabel
                    control={<Switch name="streamSettings.tlsSettings.rejectUnknownSni" checked={tlsSettings.rejectUnknownSni} onChange={handleTlsSettingsChange} />}
                    label="Reject Unknown SNI"
                />
                <FormControlLabel
                    control={<Switch name="streamSettings.tlsSettings.disableSystemRoot" checked={tlsSettings.disableSystemRoot} onChange={handleTlsSettingsChange} />}
                    label="Disable System Root"
                />
                <FormControlLabel
                    control={<Switch name="streamSettings.tlsSettings.enableSessionResumption" checked={tlsSettings.enableSessionResumption} onChange={handleTlsSettingsChange} />}
                    label="Enable Session Resumption"
                />
                <TextField
                    label="ALPN"
                    name="streamSettings.tlsSettings.alpn"
                    value={tlsSettings.alpn.join(', ')}
                    onChange={handleTlsSettingsChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Fingerprint"
                    name="streamSettings.tlsSettings.settings.fingerprint"
                    value={tlsSettings.settings.fingerprint}
                    onChange={handleTlsSettingsChange}
                    fullWidth
                    margin="normal"
                />
                <FormControlLabel
                    control={<Switch name="streamSettings.tlsSettings.settings.allowInsecure" checked={tlsSettings.settings.allowInsecure} onChange={handleTlsSettingsChange} />}
                    label="Allow Insecure"
                />
                {/*<TextField*/}
                {/*    label="Usage"*/}
                {/*    name="settings.usage"*/}
                {/*    select*/}
                {/*    value={tlsSettings.settings.usage}*/}
                {/*    onChange={handleTlsSettingsChange}*/}
                {/*    fullWidth*/}
                {/*>*/}
                {/*    {Object.values(USAGE_OPTION).map(usage => (*/}
                {/*        <MenuItem key={usage} value={usage}>*/}
                {/*            {usage}*/}
                {/*        </MenuItem>*/}
                {/*    ))}*/}
                {/*</TextField>*/}
            </FormGroup>
        </div>
    );
};

export default TlsSecurityForm;
