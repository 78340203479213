import React from 'react';
import { TextField, Box } from '@mui/material';

import {HttpSettings} from "../../models/Inbound/StreamSetting/network/HttpSettings"; // Adjust the import path as needed

interface HttpSettingsFormProps {
    httpSettings: HttpSettings;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const HttpSettingsForm: React.FC<HttpSettingsFormProps> = ({ httpSettings, handleChange }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
                label="Path"
                name="streamSettings.httpSettings.path"
                value={httpSettings.path}
                onChange={handleChange}
                fullWidth
            />
            {httpSettings.host.map((host: string, index: number) => (
                <TextField
                    key={index}
                    label={`Host ${index + 1}`}
                    name={`streamSettings.httpSettings.host[${index}]`}
                    value={host}
                    onChange={handleChange}
                    fullWidth
                />
            ))}
        </Box>
    );
};

export default HttpSettingsForm;
