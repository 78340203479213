import axiosInstance from '../utils/axiosConfig';
import {InboundDTO} from "../models/dto/Inbound";

const API_BASE_URL =  '/inbounds'; // Change this to match your API base URL

const InboundService = {
    getAll: async (): Promise<InboundDTO[]> => {
        const response = await axiosInstance.get(`${API_BASE_URL}`);
        return response.data;
    },

    create: async (inbound: InboundDTO): Promise<void> => {
        await axiosInstance.post(`${API_BASE_URL}`, inbound);
    },

    update: async (inbound: InboundDTO): Promise<void> => {
        await axiosInstance.put(`${API_BASE_URL}/${inbound.id}`, inbound);
    },

    delete: async (id: number): Promise<void> => {
        await axiosInstance.delete(`${API_BASE_URL}/${id}`);
    },

    existByPort: async (port: number): Promise<boolean> => {
        const response = await axiosInstance.get(`${API_BASE_URL}/validate-port`,  {params: { port} } );
        return response.data;
    },
};

export default InboundService;

export const getInbounds = () => axiosInstance.get<InboundDTO[]>(API_BASE_URL);

export const updateInboundToServers = async (inboundId: number, serverIds: number[]): Promise<ServerUpdateResponse> => {
    try {
        const response = await axiosInstance.post<ServerUpdateResponse>(`${API_BASE_URL}/${inboundId}/servers`, { serverIds });
        return response.data;
    } catch (error) {
        console.error('Error adding inbound to servers:', error);
        throw error;
    }
};

export const getSelectedServerIds = async  (inboundId: number) => {
    try {
        const response = await axiosInstance.get(`${API_BASE_URL}/${inboundId}/selected-servers`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch selected servers:', error);
    }
};

interface ServerUpdateResult {
    serverId: number;
    status: string;
    message: string;
}

interface ServerUpdateResponse {
    results: ServerUpdateResult[];
}