import {TlsSettings} from "./StreamSetting/security/TlsSettings";
import {XtlsSettings} from "./StreamSetting/security/XtlsSettings";
import {RealitySettings} from "./StreamSetting/security/RealitySettings";
import {TcpSettings} from "./StreamSetting/network/TcpSettings";
import {KcpSettings} from "./StreamSetting/network/KcpSettings";
import {WsSettings} from "./StreamSetting/network/WsSettings";
import {
    ALPN_OPTION,
    DOMAIN_STRATEGY_OPTION,
    NetworkType,
    ProtocolType,
    SecurityType,
    SSMethod,
    TCP_CONGESTION_OPTION,
    TLS_VERSION_OPTION,
    TransmissionType,
    USAGE_OPTION,
    UTLS_FINGERPRINT
} from "./Consts";
import {HTTPUpgradeSettings} from "./StreamSetting/network/HTTPUpgradeSettings";
import {GrpcSettings} from "./StreamSetting/network/GrpcSettings";
import {QuicSettings} from "./StreamSetting/network/QuicSettings";
import {HttpSettings} from "./StreamSetting/network/HttpSettings";
import {Sockopt} from "./StreamSetting/Sockopt";
import {Shadowsocks} from "./Setting/Shadowsocks";
import {Dekodemo} from "./Setting/Dekodemo";
import {Socks} from "./Setting/Socks";
import {Http} from "./Setting/Http";
import {Wireguard} from "./Setting/Wireguard";


export interface Inbound{
    id: number;
    remark: string;
    enable: boolean;
    port: number;
    listen: string;
    tag: string;
    protocol: ProtocolType;
    settings: Settings;
    streamSettings: StreamSetting;
    sniffing: Sniffing;
}

export interface Settings {
    shadowsocks: Shadowsocks;
    dokodemo: Dekodemo;
    socks:  Socks;
    http: Http;
    wireguard: Wireguard;
}

export interface StreamSetting {
    network: TransmissionType;
    security: SecurityType;

    tlsSettings: TlsSettings;
    xtlsSettings: XtlsSettings;
    realitySettings: RealitySettings;

    tcpSettings: TcpSettings;
    kcpSettings: KcpSettings;
    wsSettings: WsSettings;
    httpSettings: HttpSettings;
    quicSettings: QuicSettings;
    grpcSettings: GrpcSettings;
    httpUpgradeSettings?: HTTPUpgradeSettings;

    sockopt: Sockopt;
}

export interface Sniffing {
    enabled: boolean;
    destOverride: string[];
    metadataOnly: boolean;
    routeOnly: boolean;

}

export class InboundHandler {


    // canEnableTls(transmission: TransmissionType): boolean {
    //     return ["tcp", "ws", "http", "quic", "grpc", "httpupgrade"].includes(transmission);
    // }

    // this is used for xtls-rprx-vision
    // canEnableTlsFlow(): boolean {
    //     if ((this.stream.security === 'tls' || this.stream.security === 'reality') && this.network === "tcp") {
    //         return this.protocol === Protocols.VLESS;
    //     }
    //     return false;
    // }

    // canEnableReality(): boolean {
    //     if (![Protocols.VLESS, Protocols.TROJAN].includes(this.protocol)) return false;
    //     return ["tcp", "http", "grpc"].includes(this.network);
    // }
    //
    // canEnableXtls(): boolean {
    //     if (![Protocols.VLESS, Protocols.TROJAN].includes(this.protocol)) return false;
    //     return this.network === "tcp";
    // }

    static canEnableStream(protocol: ProtocolType): boolean {
        return [ProtocolType.VMESS, ProtocolType.VLESS, ProtocolType.TROJAN, ProtocolType.SHADOWSOCKS].includes(protocol);
    }
}


export function updateNestedValue(obj: any, path: string[], value: any) {
    const lastKeyIndex = path.length - 1;
    for (let i = 0; i < lastKeyIndex; ++i) {
        const key = path[i];
        if (!(key in obj)) {
            obj[key] = {};
        }
        obj = obj[key];
    }
    obj[path[lastKeyIndex]] = value;
}