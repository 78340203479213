import React from 'react';
import { TextField, Box } from '@mui/material';

import {GrpcSettings} from "../../models/Inbound/StreamSetting/network/GrpcSettings"; // Adjust the import path as needed

interface GrpcSettingsFormProps {
    grpcSettings: GrpcSettings;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const GrpcSettingsForm: React.FC<GrpcSettingsFormProps> = ({ grpcSettings, handleChange }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <TextField
                label="Service Name"
                name="streamSettings.grpcSettings.serviceName"
                value={grpcSettings.serviceName}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="Multi Mode"
                name="streamSettings.grpcSettings.multiMode"
                value={grpcSettings.multiMode}
                onChange={handleChange}
                fullWidth
            />
        </Box>
    );
};

export default GrpcSettingsForm;
