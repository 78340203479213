import React, {useState, ChangeEvent, FormEvent, useEffect} from 'react';
import {TextField, Button, Checkbox, FormControlLabel, Box, Switch} from '@mui/material';

import {Client} from "../models/client";

interface ClientFormProps {
    onSubmit: (client: Client) => void;
    client: Client;
}

const ClientFormUpdate: React.FC<ClientFormProps> = ({ onSubmit, client }) => {
    const [formData, setFormData] = useState<Client>(
        client
    );

    useEffect(() => {
        if (client) {
            setFormData(client);
        }
    }, [client]);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <Box component="form" onSubmit={handleSubmit}
             sx={{
                 display: 'flex',
                 flexDirection: 'column',
                 minWidth: '400px', // Adjust as necessary to double the size
                 boxSizing: 'border-box'
             }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={formData.enable}
                        onChange={handleChange}
                        name="enable"
                        color="primary"
                    />
                }
                label="Enable"
            />
            <TextField
                label="User Name"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                type="password"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Shadowsocks Password"
                name="shadowsocksPassword"
                value={formData.shadowsocksPassword}
                onChange={handleChange}
                type="password"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Subscription"
                name="subId"
                value={formData.subId}
                onChange={handleChange}
                fullWidth
                margin="normal"
            />
            <Button type="submit" variant="contained" color="primary">
                {client ? 'Update' : 'Create'}
            </Button>
        </Box>
);
};

export default ClientFormUpdate;
