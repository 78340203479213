import React from 'react';
import {TextField, Box, FormControlLabel, Switch} from '@mui/material';
import { KcpSettings } from '../../models/Inbound/StreamSetting/network/KcpSettings'; // Adjust the import path as needed

interface KcpSettingFormProps {
    kcpSetting: KcpSettings;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const KcpSettingsForm: React.FC<KcpSettingFormProps> = ({ kcpSetting, handleChange }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <TextField
                label="MTU"
                name="streamSettings.kcpSetting.mtu"
                type="number"
                value={kcpSetting.mtu}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="TTI"
                name="streamSettings.kcpTransmission.tti"
                type="number"
                value={kcpSetting.tti}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="Uplink Capacity"
                name="streamSettings.kcpTransmission.uplinkCapacity"
                type="number"
                value={kcpSetting.uplinkCapacity}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="Downlink Capacity"
                name="streamSettings.kcpTransmission.downlinkCapacity"
                type="number"
                value={kcpSetting.downlinkCapacity}
                onChange={handleChange}
                fullWidth
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={kcpSetting.congestion}
                        onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'streamSettings.kcpSettings.congestion', value: e.target.checked } })}
                        name="streamSettings.kcpTransmission.congestion"
                    />
                }
                label="Congestion"
            />
            <TextField
                label="Read Buffer Size"
                name="streamSettings.kcpTransmission.readBufferSize"
                type="number"
                value={kcpSetting.readBufferSize}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="Write Buffer Size"
                name="streamSettings.kcpTransmission.writeBufferSize"
                type="number"
                value={kcpSetting.writeBufferSize}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="Type"
                name="streamSettings.kcpTransmission.type"
                value={kcpSetting.type}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="Seed"
                name="streamSettings.kcpTransmission.seed"
                value={kcpSetting.seed}
                onChange={handleChange}
                fullWidth
            />
        </Box>
    );
};

export default KcpSettingsForm;
