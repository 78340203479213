import React from 'react';
import {FormControl, FormLabel, Box, Button, Divider} from '@mui/material';
import {StreamSetting} from '../../models/Inbound/Inbound';
import TlsSecurityForm from './TlsSecurityForm';
import XtlsSecurityForm from './XtlsSecurityForm';
import RealitySecurityForm from './RealitySecurityForm';
import {SecurityType} from "../../models/Inbound/Consts";

interface SecurityFormProps {
    streamSettings: StreamSetting;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const SecurityForm: React.FC<SecurityFormProps> = ({ streamSettings, handleChange }) => {


    const handleSecurityTypeChange = (security: SecurityType) => {
        const event = {
            target: {
                name: 'streamSettings.security',
                value: security,
            },
        };
        handleChange(event as React.ChangeEvent<HTMLInputElement>);
    };
    return (
        <FormControl component="fieldset">
            <Box mt={1} display="flex" gap={2}>
                <Button variant={streamSettings.security === SecurityType.None ? "contained" : "outlined"} onClick={() => handleSecurityTypeChange(SecurityType.None)}>None</Button>
                <Button variant={streamSettings.security === SecurityType.Tls ? "contained" : "outlined"} onClick={() => handleSecurityTypeChange(SecurityType.Tls)}>TLS</Button>
                <Button variant={streamSettings.security === SecurityType.Xtls ? "contained" : "outlined"} onClick={() => handleSecurityTypeChange(SecurityType.Xtls)}>XTLS</Button>
                <Button variant={streamSettings.security === SecurityType.Reality ? "contained" : "outlined"} onClick={() => handleSecurityTypeChange(SecurityType.Reality)}>Reality</Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2}} >
                {streamSettings.security === SecurityType.None && <div></div>}
                {streamSettings.security === SecurityType.Tls && <TlsSecurityForm tlsSettings={streamSettings.tlsSettings} handleChange={handleChange}/>}
                {streamSettings.security === SecurityType.Xtls && <XtlsSecurityForm xtlsSettings={streamSettings.xtlsSettings} handleChange={handleChange} />}
                {streamSettings.security === SecurityType.Reality && <RealitySecurityForm realitySettings={streamSettings.realitySettings} handleChange={handleChange} />}
            </Box>
        </FormControl>
    );
};

export default SecurityForm;
