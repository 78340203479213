import React from 'react';
import { TextField, MenuItem, Box } from '@mui/material';

import {Shadowsocks} from "../../models/Inbound/Setting/Shadowsocks";
import {NetworkType, SSMethod} from "../../models/Inbound/Consts"; // Adjust the import path as needed

interface ShadowsocksFormProps {
    shadowsocks: Shadowsocks;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const ShadowsocksForm: React.FC<ShadowsocksFormProps> = ({ shadowsocks, handleChange }) => {
    const isPasswordRequired = (method: SSMethod) => method.includes('2022');

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <TextField
                select
                label="Method"
                name="settings.shadowsocks.method"
                value={shadowsocks.method}
                onChange={handleChange}
                fullWidth
            >
                {Object.values(SSMethod).map((method) => (
                    <MenuItem key={method} value={method}>
                        {method}
                    </MenuItem>
                ))}
            </TextField>
            {isPasswordRequired(shadowsocks.method) && (
                <TextField
                    label="Password"
                    name="settings.shadowsocks.password"
                    type="password"
                    value={shadowsocks.password}
                    onChange={handleChange}
                    fullWidth
                />
            )}
            <TextField
                select
                label="Network"
                name="settings.shadowsocks.network"
                value={shadowsocks.network}
                onChange={handleChange}
                fullWidth
            >
                {Object.values(NetworkType).map((network) => (
                    <MenuItem key={network} value={network}>
                        {network}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};

export default ShadowsocksForm;
