import React, {useState, FormEvent, ChangeEvent, useEffect} from 'react';
import { TextField, Button, Box, MenuItem } from '@mui/material';

import { Client } from "../models/client";
import { RandomUtil } from "../js/util/utils";
import {existByEmail} from "../services/clientService";
import {getSubscriptionDomains} from "../services/domainService";
import {Domain} from "../models/domain";

interface ClientFormProps {
    onSubmit: (client: Client) => void;
}

const ClientFormAdd: React.FC<ClientFormProps> = ({ onSubmit }) => {
    const [formData, setFormData] = useState<Client>({
        id: 0,
        password: RandomUtil.randomSeq(10),
        shadowsocksPassword: RandomUtil.randomShadowsocksPassword(),
        email: RandomUtil.randomLowerAndNum(8),
        dataCap: 50,  // Default to 50GB
        expiryTime: new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString(),  // Default to 1 month from now
        enable: true,
        subId: RandomUtil.randomLowerAndNum(16),
        createdAt: '',
        up: 0,
        down: 0,
        domainId: undefined,
        contact: ''
    });
    const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [monthsError, setMonthsError] = useState<string | null>(null);
    const [dataCapError, setDataCapError] = useState<string | null>(null);
    const [domains, setDomains] = useState<Domain[]>([]);

    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const response = await getSubscriptionDomains();
                setDomains(response.data);
            } catch (error) {
                console.error('Failed to fetch domains', error);
            }
        };

        fetchDomains();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
        const months = parseInt(e.target.value);
        const now = new Date();
        const expiryDate = new Date(now.setMonth(now.getMonth() + months));
        setFormData({
            ...formData,
            expiryTime: expiryDate.toISOString(),
        });
    };

    const handleDataCapChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            dataCap: parseInt(e.target.value),
        });
    };

    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmailError(null); // Reset error
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        setDebounceTimer(setTimeout(() => validateEmail(e.target.value as string), 500));

        handleChange(e);
    };

    const validateEmail = async (email: string) => {
        try {
            const isDuplicate = await existByEmail(email);
            if (isDuplicate) {
                setEmailError('Email already exists.');
            } else {
                setEmailError(null);
            }
        } catch (error) {
            console.error('Failed to validate email', error);
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        let valid = true;

        if (!formData.expiryTime) {
            setMonthsError('Please select a valid month.');
            valid = false;
        }

        if (!formData.dataCap) {
            setDataCapError('Please select a valid data cap.');
            valid = false;
        }

        if (valid && !emailError) {
            onSubmit(formData);
        }
    };


    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '400px', // Adjust as necessary to double the size
                boxSizing: 'border-box'
            }}
        >
            <TextField
                label="User name"
                name="email"
                value={formData.email}
                onChange={handleChangeEmail}
                fullWidth
                margin="normal"
                error={!!emailError}
                helperText={emailError}
            />
            {/*<TextField*/}
            {/*    label="Password"*/}
            {/*    name="password"*/}
            {/*    value={formData.password}*/}
            {/*    onChange={handleChange}*/}
            {/*    type="password"*/}
            {/*    fullWidth*/}
            {/*    margin="normal"*/}
            {/*/>*/}
            {/*<TextField*/}
            {/*    label="Shadowsocks Password"*/}
            {/*    name="shadowsocksPassword"*/}
            {/*    value={formData.shadowsocksPassword}*/}
            {/*    onChange={handleChange}*/}
            {/*    type="password"*/}
            {/*    fullWidth*/}
            {/*    margin="normal"*/}
            {/*/>*/}
            {/*<TextField*/}
            {/*    label="Subscription"*/}
            {/*    name="subId"*/}
            {/*    value={formData.subId}*/}
            {/*    onChange={handleChange}*/}
            {/*    fullWidth*/}
            {/*    margin="normal"*/}
            {/*/>*/}

            <TextField
                select
                label="Months"
                name="expiryTime"
                value={1}  // Default to 1 month
                onChange={handleMonthChange}
                fullWidth
                error={!!monthsError}
                helperText={monthsError}
            >
                <MenuItem value={1}>1 Month</MenuItem>
                <MenuItem value={3}>3 Months</MenuItem>
                <MenuItem value={6}>6 Months</MenuItem>
                <MenuItem value={12}>12 Months</MenuItem>
            </TextField>

            <TextField
                select
                label="Data Cap"
                name="dataCap"
                value={50}  // Default to 50GB
                onChange={handleDataCapChange}
                fullWidth
                error={!!dataCapError}
                helperText={dataCapError}
            >
                <MenuItem value={25}>25G</MenuItem>
                <MenuItem value={50}>50G</MenuItem>
                <MenuItem value={80}>80G</MenuItem>
                <MenuItem value={120}>120G</MenuItem>
                <MenuItem value={200}>200G</MenuItem>
                <MenuItem value={300}>300G</MenuItem>
                <MenuItem value={500}>500G</MenuItem>
            </TextField>
            <TextField
                select
                label="Domain"
                name="domainId"
                value={formData.domainId ?? ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
            >
                {domains.length > 0 && domains.map((domain) => (
                    <MenuItem key={domain.id} value={domain.id}>
                        {domain.root}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                label="Contact"
                name="contact"
                value={formData.contact ?? ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
            />
            <Button type="submit" variant="contained" color="primary">
                {'Create'}
            </Button>
        </Box>
    );
};

export default ClientFormAdd;
