import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react';
import {
    TextField,
    Button,
    Box,
    Switch,
    FormControlLabel,
    Typography,
    FormGroup,
    Checkbox,
    MenuItem,

} from '@mui/material';
import {Country, countryFlagMap} from '../models/Country';
import {Server} from "../models/server";
import {ProtocolType, TransmissionType} from "../models/Inbound/Consts";

interface ServerFormProps {
    server?: Server;
    onSubmit: (server: Server) => void;
}

const ServerForm: React.FC<ServerFormProps> = ({server, onSubmit}) => {
    const [formData, setFormData] = useState<Server>({
        id: server?.id || 0,
        ip: server?.ip || '',
        enable: server?.enable || true,
        tag: server?.tag || '',
        usages: server?.usages || [
            'vmess',
            'vless',
            'trojan',
            'shadowsocks',
            'socks',
            'http',
            'wireguard',
            'Tcp',
            'Kcp',
            'Ws',
            'Quic',
            'Grpc',
            'HTTP',
            'HTTPUpgrade',
        ],
        country: server?.country || Country.US ,// Default to US,
        inboundCount: server?.inboundCount || 0,
        clientCount: server?.clientCount || 0,
    });

    useEffect(() => {
        if (server) {
            setFormData(server);
        }
    }, [server]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value, checked} = e.target;
        setFormData({...formData, [name]: name === 'enable' ? checked : value});
    };

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedCountry = event.target.value as Country; // Ensure it's typed as Country enum
        setFormData({ ...formData, country: selectedCountry });
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        let newUsages = [...formData.usages];
        if (checked && !newUsages.includes(name)) {
            newUsages.push(name);
        } else if (!checked && newUsages.includes(name)) {
            newUsages = newUsages.filter((item) => item !== name);
        }
        setFormData({...formData, usages: newUsages});
    };

    return (
        <Box component="form" onSubmit={handleSubmit} autoComplete="off"
             sx={{
                 display: 'flex',
                 flexDirection: 'column',
                 minWidth: '400px', // Adjust as necessary to double the size
                 boxSizing: 'border-box'
             }}>
            <FormControlLabel
                control={<Switch checked={formData.enable} onChange={handleChange} name="enable"
                                 defaultChecked={true}/>}
                label="Enable"
            />
            <TextField
                name="ip"
                label="IP"
                value={formData.ip}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
            />

            <TextField
                name="tag"
                label="Tag"
                value={formData.tag}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
            />
            <TextField
                select
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleSelectChange}
                fullWidth
                margin="normal"
            >
                {Object.values(Country).map((country) => (
                    <MenuItem key={country} value={country}>
                        {countryFlagMap[country]} {country}
                    </MenuItem>
                ))}
            </TextField>

            <Box sx={{m: 1}}>
                <Typography gutterBottom>
                    Protocols
                </Typography>
                <FormGroup row>

                    {Object.values(ProtocolType).map((option) => (
                        <FormControlLabel
                            key={option}
                            control={
                                <Checkbox
                                    checked={formData.usages.includes(option)}
                                    onChange={handleCheckboxChange}
                                    name={option}
                                />
                            }
                            label={option}
                        />
                    ))}
                </FormGroup>
            </Box>

            <Box sx={{m: 1}}>
                <Typography gutterBottom>
                    Transmissions
                </Typography>
                <FormGroup row>

                    {Object.values(TransmissionType).map((option) => (
                        <FormControlLabel
                            key={option}
                            control={
                                <Checkbox
                                    checked={formData.usages.includes(option)}
                                    onChange={handleCheckboxChange}
                                    name={option}
                                />
                            }
                            label={option}
                        />
                    ))}
                </FormGroup>
            </Box>

            <Button type="submit" variant="contained" color="primary">
                {server ? 'Update Server' : 'Add Server'}
            </Button>
        </Box>
    );
};

export default ServerForm;
