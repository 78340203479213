// src/pages/DomainManagement.tsx

import React, { useState, useEffect } from 'react';
import DomainForm from '../components/DomainForm';
import DomainList from '../components/DomainList';
import { getDomains, createDomain, updateDomain, deleteDomain } from '../services/domainService';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material';
import {Domain} from "../models/domain";

const DomainManagement: React.FC = () => {
    const [domains, setDomains] = useState<Domain[]>([]);
    const [filteredDomains, setFilteredDomains] = useState<Domain[]>([]);
    const [currentDomain, setCurrentDomain] = useState<Domain | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [domainToDelete, setDomainToDelete] = useState<number | undefined>(undefined);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchDomains();
    }, []);

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredDomains(domains);
        } else {
            setFilteredDomains(domains.filter(domain => domain.root.toLowerCase().includes(searchTerm.toLowerCase())));
        }
    }, [searchTerm, domains]);

    const fetchDomains = async () => {
        try {
            const response = await getDomains();
            setDomains(response.data);
            setFilteredDomains(response.data);
        } catch (error) {
            console.error('Failed to fetch domains:', error);
        }
    };

    const handleCreateOrUpdate = async (domain: Domain) => {
        try {
            if (domain.id) {
                await updateDomain(domain.id, domain);
            } else {
                await createDomain(domain);
            }
            fetchDomains();
            setCurrentDomain(undefined);
            setOpen(false);
        } catch (error) {
            console.error('Failed to create or update domain:', error);
        }
    };

    const handleEdit = (domain: Domain) => {
        setCurrentDomain(domain);
        setOpen(true);
    };

    const handleOpen = () => {
        setCurrentDomain(undefined);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteClick = (id: number) => {
        setDomainToDelete(id);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (domainToDelete) {
            try {
                await deleteDomain(domainToDelete);
                fetchDomains();
                setDomainToDelete(undefined);
                setDeleteDialogOpen(false);
            } catch (error) {
                console.error('Failed to delete domain:', error);
            }
        }
    };

    const handleDeleteCancel = () => {
        setDomainToDelete(undefined);
        setDeleteDialogOpen(false);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Domain Management
            </Typography>
            <Box mb={2} display="flex" justifyContent="space-between">
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    Add Domain
                </Button>
            </Box>
            <DomainList domains={filteredDomains} onEdit={handleEdit} onDelete={handleDeleteClick} />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentDomain ? 'Edit Domain' : 'Add Domain'}</DialogTitle>
                <DialogContent>
                    <DomainForm onSubmit={handleCreateOrUpdate} domain={currentDomain} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this domain?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DomainManagement;
