
import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Button,
    Paper,
    Typography,
    Box,
    FormControlLabel,
    Switch, Popover
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {InboundDTO} from "../models/dto/Inbound";
import { getInbounds } from '../services/InboundService';
import { updateServerToInbounds, getSelectedInboundIds } from "../services/serverService";

interface InboundWithStatus extends InboundDTO {
    status?: string;
    message?: string;
}

const ServerInboundsManagementPage: React.FC = () => {
    const [inbounds, setInbounds] = useState<InboundWithStatus[]>([]);
    const [selectedInboundIds, setSelectedInboundIds] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [saveStatus, setSaveStatus] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const { serverId } = useParams<{ serverId: string }>();
    const numericServerId = Number(serverId); // Convert to number
    const navigate = useNavigate();

    useEffect(() => {
        fetchInbounds();
        fetchSelectedInbounds();
    }, []);

    const fetchInbounds = async () => {
        try {
            const response = await getInbounds();
            setInbounds(response.data);
        } catch (error) {
            console.error('Failed to fetch inbounds:', error);
        }
    };

    const fetchSelectedInbounds = async () => {
        try {
            const response = await getSelectedInboundIds(numericServerId);
            setSelectedInboundIds(response || []);
        } catch (error) {
            console.error('Failed to fetch selected inbounds:', error);
            setSelectedInboundIds([]);
        }
    };

    const handleCheckboxChange = (inboundId: number) => {
        setSelectedInboundIds(prevState =>
            prevState.includes(inboundId)
                ? prevState.filter(id => id !== inboundId)
                : [...prevState, inboundId]
        );
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedInboundIds([]);
        } else {
            const allInboundIds = inbounds.map(inbound => inbound.id);
            setSelectedInboundIds(allInboundIds);
        }
        setSelectAll(!selectAll);
    };

    const handleSave = async () => {
        try {
            const result = await updateServerToInbounds(numericServerId, selectedInboundIds);
            const updatedInbounds = inbounds.map(inbound => {
                const resultForInbound = result.results.find(r => r.inboundId === inbound.id);
                return {
                    ...inbound,
                    status: resultForInbound?.status,
                    message: resultForInbound?.message
                };
            });
            setInbounds(updatedInbounds);

            const allSucceeded = result.results.every(r => r.status !== 'Failed');
            if (allSucceeded) {
                setSaveStatus('Success');
                setTimeout(() => {
                    setSaveStatus(null); // Reset save status after success
                    navigate(-1); // Navigate back after 2 seconds
                }, 2000);
            } else {
                setSaveStatus('PartialSuccess');
            }
        } catch (error) {
            console.error('Failed to save server inbounds:', error);
            setSaveStatus('Failed');
            // Handle save error
            const updatedInbounds = inbounds.map(inbound => ({
                ...inbound,
                status: 'Failed',
                message: 'Failed to save changes'
            }));
            setInbounds(updatedInbounds);
        }
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Manage Inbounds for Server {serverId}
            </Typography>
            <Box m={2} display="flex" justifyContent="flex-end">
                <Button onClick={handleSave} variant="contained" color="primary">
                    Save
                </Button>
            </Box>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                        indeterminate={
                                            selectedInboundIds.length > 0 &&
                                            selectedInboundIds.length < inbounds.length
                                        }
                                    />
                                </TableCell>
                                <TableCell>Inbound ID</TableCell>
                                <TableCell>Tag</TableCell>
                                <TableCell>Enabled</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {inbounds.slice(0, 10).map(inbound => (
                                <TableRow key={inbound.id}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedInboundIds.includes(inbound.id)}
                                            onChange={() => handleCheckboxChange(inbound.id)}
                                        />
                                    </TableCell>
                                    <TableCell>{inbound.id}</TableCell>
                                    <TableCell>{inbound.tag}</TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={<Switch checked={inbound.enable} />}
                                            label={""}
                                        />
                                    </TableCell>
                                    <TableCell>{inbound.status}</TableCell>
                                    <TableCell
                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseLeave={handlePopoverClose}
                                        onClick={handlePopoverOpen}
                                        sx={{
                                            maxWidth: '100px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            m: 2,
                                        }}>

                                        {inbound.message}

                                        <Popover
                                            id="mouse-over-popover"
                                            sx={{
                                                pointerEvents: 'none',
                                            }}
                                            open={open}

                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                        >
                                            <Typography sx={{p: 1, maxWidth: '300px',}}>{inbound.message}</Typography>
                                        </Popover>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

export default ServerInboundsManagementPage;
