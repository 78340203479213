import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react';
import {TextField, Button, Box, Switch, FormControlLabel, FormGroup, Checkbox, Typography} from '@mui/material';
import {ProtocolType, TransmissionType,} from "../models/Inbound/Consts";
import {Domain} from "../models/domain";

interface DomainFormProps {
    domain?: Domain;
    onSubmit: (domain: Domain) => void;
}

const DomainForm: React.FC<DomainFormProps> = ({domain, onSubmit}) => {
    const defaultUsages = [
        'vmess',
        'vless',
        'trojan',
        'shadowsocks',
        'socks',
        'http',
        'wireguard',
        'Tcp',
        'Kcp',
        'Ws',
        'Quic',
        'Grpc',
        'HTTP',
        'HTTPUpgrade',
    ];
    const [formData, setFormData] = useState<Domain>({
        id: domain?.id || 0,
        root: domain?.root || '',
        enable: domain?.enable || true,
        tag: domain?.tag || '',
        usages: domain?.usages || defaultUsages,
    });

    useEffect(() => {
        if (domain) {
            setFormData(domain);
        }
    }, [domain]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value, checked} = e.target;
        setFormData({...formData, [name]: name === 'enable' ? checked : value});
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        let newUsages = [...formData.usages];

        if (name === 'sub') {
            if (checked) {
                newUsages = ['sub'];
            } else {
                newUsages = defaultUsages;
            }
        } else {
            if (checked && !newUsages.includes(name)) {
                newUsages.push(name);
            } else if (!checked && newUsages.includes(name)) {
                newUsages = newUsages.filter((item) => item !== name);
            }
            if (newUsages.includes('sub')) {
                newUsages = newUsages.filter((item) => item !== 'sub');
            }
        }

        setFormData({ ...formData, usages: newUsages });
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} autoComplete="off"
             sx={{
                 display: 'flex',
                 flexDirection: 'column',
                 minWidth: '300px', // Adjust as necessary to double the size
                 boxSizing: 'border-box'
             }}>
            <FormControlLabel
                control={<Switch checked={formData.enable} onChange={handleChange} name="enable"
                                 defaultChecked={true}/>}
                label="Enable"
            />
            <TextField
                name="root"
                label="Root"
                value={formData.root}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
            />

            <TextField
                name="tag"
                label="Tag"
                value={formData.tag}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
            />
            <Box sx={{m: 1}}>
                <Typography  gutterBottom>
                    Protocols
                </Typography>
                <FormGroup row>

                    {Object.values(ProtocolType).map((option) => (
                        <FormControlLabel
                            key={option}
                            control={
                                <Checkbox
                                    checked={formData.usages.includes(option)}
                                    onChange={handleCheckboxChange}
                                    name={option}
                                    disabled={formData.usages.includes('sub')}
                                />
                            }
                            label={option}
                        />
                    ))}
                </FormGroup>
            </Box>

            <Box sx={{m: 1}}>
                <Typography  gutterBottom>
                    Transmissions
                </Typography>
                <FormGroup row>

                    {Object.values(TransmissionType).map((option) => (
                        <FormControlLabel
                            key={option}
                            control={
                                <Checkbox
                                    checked={formData.usages.includes(option)}
                                    onChange={handleCheckboxChange}
                                    name={option}
                                    disabled={formData.usages.includes('sub')}
                                />
                            }
                            label={option}
                        />
                    ))}
                </FormGroup>
            </Box>

            <Box sx={{m: 1}}>
                <Typography  gutterBottom>
                    Other Usages
                </Typography>
                <FormGroup row>
                    <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.usages.includes("sub")}
                                    onChange={handleCheckboxChange}
                                    name = { "sub" }
                                />
                            }
                            label={"subscription link"}
                    />
                </FormGroup>
            </Box>
            <Button type="submit" variant="contained" color="primary">
                {domain ? 'Update Domain' : 'Add Domain'}
            </Button>
        </Box>
    );
};

export default DomainForm;
