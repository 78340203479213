import React, { useState, useEffect } from 'react';
import ClientFormAdd from '../components/ClientFormAdd';
import ClientFormUpdate from '../components/ClientFormUpdate';
import ClientList from '../components/ClientList';
import {getClients, createClient, updateClient, deleteClient, renewClient} from '../services/clientService';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    MenuItem
} from '@mui/material';
import { Client } from "../models/client";
import {Domain} from "../models/domain";
import {getSubscriptionDomains} from "../services/domainService";

const ClientManagement: React.FC = () => {
    const [clients, setClients] = useState<Client[]>([]);
    const [filteredClients, setFilteredClients] = useState<Client[]>([]);
    const [currentClient, setCurrentClient] = useState<Client | undefined>(undefined);
    const [openAdd, setOpenAdd] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openRenew, setOpenRenew] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [clientToDelete, setClientToDelete] = useState<number | undefined>(undefined);
    const [searchTerm, setSearchTerm] = useState('');
    const [domains, setDomains] = useState<Domain[]>([]);
    const [renewalData, setRenewalData] = useState({ newDataCap: 50, renewalPeriodMonths: 1 }); // Defaults to 50GB and 1 month

    useEffect(() => {
        fetchDomains();
        fetchClients();
    }, []);

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredClients(clients);
        } else {
            setFilteredClients(clients.filter(client => client.subId.toLowerCase().includes(searchTerm.toLowerCase())
                                                ||
                                                client.contact?.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        }
    }, [searchTerm, clients]);

    const fetchClients = async () => {
        try {
            const response = await getClients();
            setClients(response.data);
            setFilteredClients(response.data);
        } catch (error) {
            console.error('Failed to fetch clients:', error);
        }
    };

    const fetchDomains = async () => {
        try {
            const response = await getSubscriptionDomains();
            setDomains(response.data);
        } catch (error) {
            console.error('Failed to fetch domains', error);
        }
    };
    const handleCreate = async (client: Client) => {
        try {
            await createClient(client);
            fetchClients();
            setOpenAdd(false);
        } catch (error) {
            console.error('Failed to create client:', error);
        }
    };

    const handleUpdate = async (client: Client) => {
        try {
            if (client.id) {
                await updateClient(client.id, client);
                fetchClients();
                setCurrentClient(undefined);
                setOpenUpdate(false);
            }
        } catch (error) {
            console.error('Failed to update client:', error);
        }
    };

    const handleEdit = (client: Client) => {
        setCurrentClient(client);
        setOpenUpdate(true);
    };

    const handleOpenAdd = () => {
        setCurrentClient(undefined);
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
    };

    const handleDeleteClick = (id: number) => {
        setClientToDelete(id);
        setDeleteDialogOpen(true);
    };

    const handleRenewClick = (client: Client) => {
        setCurrentClient(client);
        setOpenRenew(true);
    };

    const handleDeleteConfirm = async () => {
        if (clientToDelete) {
            try {
                await deleteClient(clientToDelete);
                fetchClients();
                setClientToDelete(undefined);
                setDeleteDialogOpen(false);
            } catch (error) {
                console.error('Failed to delete client:', error);
            }
        }
    };

    const handleDeleteCancel = () => {
        setClientToDelete(undefined);
        setDeleteDialogOpen(false);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleRenewChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const { name, value } = event.target;
        setRenewalData(prevData => ({
            ...prevData,
            [name!]: value
        }));
    };

    const handleRenewSubmit = async () => {
        if (currentClient) {
            try {
                await renewClient(currentClient.id, renewalData.newDataCap, renewalData.renewalPeriodMonths);
                fetchClients();
                setOpenRenew(false);
                setCurrentClient(undefined);
            } catch (error) {
                console.error('Failed to renew client:', error);
            }
        }
    };


    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Client Management
            </Typography>
            <Box mb={2} display="flex" justifyContent="space-between">
                <TextField
                    label="SUB ID / Contact"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <Button variant="contained" color="primary" onClick={handleOpenAdd}>
                    Add Client
                </Button>
            </Box>
            <ClientList clients={filteredClients} onEdit={handleEdit} onDelete={handleDeleteClick} domains={domains} onRenew={handleRenewClick}/>
            <Dialog open={openAdd} onClose={handleCloseAdd}>
                <DialogTitle>Add Client</DialogTitle>
                <DialogContent>
                    <ClientFormAdd onSubmit={handleCreate} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAdd} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openUpdate} onClose={handleCloseUpdate}>
                <DialogTitle>Edit Client</DialogTitle>
                <DialogContent>
                    <ClientFormUpdate onSubmit={handleUpdate} client={currentClient!} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUpdate} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this client?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openRenew} onClose={() => setOpenRenew(false)}>
                <DialogTitle>Renew Client Package</DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        label="Months"
                        name="renewalPeriodMonths"
                        value={renewalData.renewalPeriodMonths}
                        onChange={handleRenewChange}
                        fullWidth
                    >
                        <MenuItem value={1}>1 Month</MenuItem>
                        <MenuItem value={3}>3 Months</MenuItem>
                        <MenuItem value={6}>6 Months</MenuItem>
                        <MenuItem value={12}>12 Months</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Data Cap"
                        name="newDataCap"
                        value={renewalData.newDataCap}
                        onChange={handleRenewChange}
                        fullWidth
                    >
                        <MenuItem value={25}>25G</MenuItem>
                        <MenuItem value={50}>50G</MenuItem>
                        <MenuItem value={80}>80G</MenuItem>
                        <MenuItem value={120}>120G</MenuItem>
                        <MenuItem value={200}>200G</MenuItem>
                        <MenuItem value={300}>300G</MenuItem>
                        <MenuItem value={500}>500G</MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRenew(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleRenewSubmit} color="primary">
                        Renew
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ClientManagement;
