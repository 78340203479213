import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container, CssBaseline, ThemeProvider } from '@mui/material';
import SideMenu from './components/SideMenu';
import ClientManagement from './pages/ClientManagement';
import DomainManagement from './pages/DomainManagement';
import ServerManagement from './pages/ServerManagement';
import InboundManagement from './pages/InboundManagement';
import theme from './theme';
import InboundServersManagementPage from './pages/InboundServersManagementPage';
import ServerInboundsManagementPage from './pages/ServerInboundsManagementPage';
import Login from './components/Login';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import DashboardPage from './pages/DashboardPage';        // Import new page

const App: React.FC = () => {
    return (
        <AuthProvider>
            <Router>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div style={{ display: 'flex' }}>
                        <SideMenu />
                        <Container style={{ marginLeft: 0, padding: 20 }}>
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route element={<ProtectedRoute />}>
                                    <Route path="/dashboard" element={<DashboardPage />} />
                                    <Route path="/clients" element={<ClientManagement />} />
                                    <Route path="/domains" element={<DomainManagement />} />
                                    <Route path="/servers" element={<ServerManagement />} />
                                    <Route path="/inbound" element={<InboundManagement />} />
                                    <Route path="/manage-servers/:inboundId" element={<InboundServersManagementPage />} />
                                    <Route path="/manage-inbounds/:serverId" element={<ServerInboundsManagementPage />} />
                                    <Route path="*" element={<Navigate to="/dashboard" />} /> {/* Default Route */}
                                </Route>
                            </Routes>
                        </Container>
                    </div>
                </ThemeProvider>
            </Router>
        </AuthProvider>
    );
};

export default App;
