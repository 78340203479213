import React from 'react';
import { FormControlLabel, Checkbox, Box, Switch, FormGroup } from '@mui/material';
import { Sniffing } from '../models/Inbound/Inbound';
import {SNIFFING_OPTION} from "../models/Inbound/Consts"; // Adjust the import path as needed

interface SniffingFormProps {
    sniffing: Sniffing;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const SniffingForm: React.FC<SniffingFormProps> = ({ sniffing, handleChange }) => {
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        let newDestOverride = [...sniffing.destOverride];
        if (checked) {
            newDestOverride.push(name);
        } else {
            newDestOverride = newDestOverride.filter(item => item !== name);
        }
        handleChange({ target: { name: 'sniffing.destOverride', value: newDestOverride } } as any);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={sniffing.enabled}
                        onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'sniffing.enabled', value: e.target.checked } })}
                        name="sniffing.enabled"
                    />
                }
                label="Enable Sniffing"
            />
            {sniffing.enabled && (
                <>
                    <FormGroup row>
                        {Object.values(SNIFFING_OPTION).map(option => (
                            <FormControlLabel
                                key={option}
                                control={
                                    <Checkbox
                                        checked={sniffing.destOverride.includes(option)}
                                        onChange={handleCheckboxChange}
                                        name={option}
                                    />
                                }
                                label={option}
                            />
                        ))}
                    </FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={sniffing.metadataOnly}
                                onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'sniffing.metadataOnly', value: e.target.checked } })}
                                name="sniffing.metadataOnly"
                            />
                        }
                        label="Metadata Only"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={sniffing.routeOnly}
                                onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'sniffing.routeOnly', value: e.target.checked } })}
                                name="sniffing.routeOnly"
                            />
                        }
                        label="Route Only"
                    />
                </>
            )}
        </Box>
    );
};

export default SniffingForm;
