// src/pages/ServerManagement.tsx

import React, { useState, useEffect } from 'react';
import ServerForm from '../components/ServerForm';
import ServerList from '../components/ServerList';
import { getServers, createServer, updateServer, deleteServer } from '../services/serverService';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material';
import {Server} from "../models/server";

const ServerManagement: React.FC = () => {
    const [servers, setServers] = useState<Server[]>([]);
    const [filteredServers, setFilteredServers] = useState<Server[]>([]);
    const [currentServer, setCurrentServer] = useState<Server | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [serverToDelete, setServerToDelete] = useState<number | undefined>(undefined);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchServers();
    }, []);

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredServers(servers);
        } else {
            setFilteredServers(servers.filter(server => server.ip.toLowerCase().includes(searchTerm.toLowerCase())));
        }
    }, [searchTerm, servers]);

    const fetchServers = async () => {
        try {
            const response = await getServers();
            setServers(response.data);
            setFilteredServers(response.data);
        } catch (error) {
            console.error('Failed to fetch servers:', error);
        }
    };

    const handleCreateOrUpdate = async (server: Server) => {
        try {
            if (server.id) {
                await updateServer(server.id, server);
            } else {
                await createServer(server);
            }
            fetchServers();
            setCurrentServer(undefined);
            setOpen(false);
        } catch (error) {
            console.error('Failed to create or update server:', error);
        }
    };

    const handleEdit = (server: Server) => {
        setCurrentServer(server);
        setOpen(true);
    };

    const handleOpen = () => {
        setCurrentServer(undefined);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteClick = (id: number) => {
        setServerToDelete(id);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (serverToDelete) {
            try {
                await deleteServer(serverToDelete);
                fetchServers();
                setServerToDelete(undefined);
                setDeleteDialogOpen(false);
            } catch (error) {
                console.error('Failed to delete server:', error);
            }
        }
    };

    const handleDeleteCancel = () => {
        setServerToDelete(undefined);
        setDeleteDialogOpen(false);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Server Management
            </Typography>
            <Box mb={2} display="flex" justifyContent="space-between">
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    Add Server
                </Button>
            </Box>
            <ServerList servers={filteredServers} onEdit={handleEdit} onDelete={handleDeleteClick} />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentServer ? 'Edit Server' : 'Add Server'}</DialogTitle>
                <DialogContent>
                    <ServerForm onSubmit={handleCreateOrUpdate} server={currentServer} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this server?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ServerManagement;
