import React from 'react';
import { TextField, Box } from '@mui/material';
import {Header, HTTPUpgradeSettings} from "../../models/Inbound/StreamSetting/network/HTTPUpgradeSettings"; // Adjust the import path as needed

interface HTTPUpgradeSettingsFormProps {
    httpUpgradeSettings: HTTPUpgradeSettings;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const HTTPUpgradeSettingsForm: React.FC<HTTPUpgradeSettingsFormProps> = ({ httpUpgradeSettings, handleChange }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
                label="Path"
                name="streamSettings.httpUpgradeSettings.path"
                value={httpUpgradeSettings.path}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="Host"
                name="streamSettings.httpUpgradeSettings.host"
                value={httpUpgradeSettings.host}
                onChange={handleChange}
                fullWidth
            />
            {httpUpgradeSettings.headers.map((header: Header, index: number) => (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <TextField
                        label={`Header ${index + 1} Name`}
                        name={`streamSettings.httpUpgradeSettings.headers[${index}].name`}
                        value={header.name}
                        onChange={handleChange}
                        fullWidth
                    />
                    <TextField
                        label={`Header ${index + 1} Value`}
                        name={`streamSettings.httpUpgradeSettings.headers[${index}].value`}
                        value={header.value}
                        onChange={handleChange}
                        fullWidth
                    />
                </Box>
            ))}
        </Box>
    );
};

export default HTTPUpgradeSettingsForm;
