import React from 'react';
import {TextField, FormGroup, Typography, Grid, MenuItem, FormHelperText} from '@mui/material';
import {RealitySettings} from "../../models/Inbound/StreamSetting/security/RealitySettings";
import {UTLS_FINGERPRINT} from "../../models/Inbound/Consts";

interface RealitySecurityFormProps {
    realitySettings: RealitySettings;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const RealitySecurityForm: React.FC<RealitySecurityFormProps> = ({ realitySettings, handleChange }) => {
    const handleRealitySettingsChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        console.log('change', e);
        handleChange(e);
    };

    return (
        <div>
            <Typography variant="h6"></Typography>
            <FormGroup>
                <TextField
                    label="Public Key"
                    name="streamSettings.realitySettings.settings.publicKey"
                    value={realitySettings.settings.publicKey}
                    onChange={handleRealitySettingsChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Destination"
                    name="streamSettings.realitySettings.dest"
                    value={realitySettings.dest}
                    onChange={handleRealitySettingsChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Server Names"
                    name="streamSettings.realitySettings.serverNames"
                    value={realitySettings.serverNames.join(', ')}
                    onChange={handleRealitySettingsChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Private Key"
                    name="streamSettings.realitySettings.privateKey"
                    value={realitySettings.privateKey}
                    onChange={handleRealitySettingsChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Minimum Client Version"
                    name="streamSettings.realitySettings.minClient"
                    value={realitySettings.minClient}
                    onChange={handleRealitySettingsChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Maximum Client Version"
                    name="streamSettings.realitySettings.maxClient"
                    value={realitySettings.maxClient}
                    onChange={handleRealitySettingsChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Maximum Time Difference"
                    name="streamSettings.realitySettings.maxTimediff"
                    type="number"
                    value={realitySettings.maxTimediff}
                    onChange={handleRealitySettingsChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Short IDs"
                    name="streamSettings.realitySettings.shortIds"
                    value={realitySettings.shortIds.join(', ')}
                    onChange={handleRealitySettingsChange}
                    fullWidth
                    margin="normal"
                />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Fingerprint"
                            name="streamSettings.realitySettings.settings.fingerprint"
                            select
                            value={realitySettings.settings.fingerprint}
                            onChange={handleRealitySettingsChange}
                            fullWidth
                            margin="normal"
                        >
                            {Object.values(UTLS_FINGERPRINT).map(fingerprint => (
                                <MenuItem key={fingerprint} value={fingerprint}>
                                    {fingerprint}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Spider X"
                            name="streamSettings.realitySettings.settings.spiderX"
                            value={realitySettings.settings.spiderX}
                            onChange={handleRealitySettingsChange}
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                {/*<FormControlLabel*/}
                {/*    control={<Switch name="settings.show" checked={realitySettings.settings.show} onChange={handleRealitySettingsChange} />}*/}
                {/*    label="Show"*/}
                {/*/>*/}
                {/*<TextField*/}
                {/*    label="X Version"*/}
                {/*    name="settings.xver"*/}
                {/*    type="number"*/}
                {/*    value={realitySettings.settings.xver}*/}
                {/*    onChange={handleRealitySettingsChange}*/}
                {/*    fullWidth*/}
                {/*/>*/}
            </FormGroup>
        </div>
    );
};

export default RealitySecurityForm;
