import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',  // custom primary color
        },
        secondary: {
            main: '#dc004e',  // custom secondary color
        },
    },
    typography: {
        fontSize: 14,  // base font size
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: 'small',  // default to small size
            },
            styleOverrides: {
                root: {
                    marginTop: '6px',
                    marginBottom: '6px',
                    '& .MuiInputBase-input': {
                        padding: '8px 12px',  // Adjust padding as necessary
                        fontSize: '14px',
                    },
                    '& .MuiInputLabel-root': {
                        fontSize: '14px',  // smaller label font size
                        top: '50%',
                        transform: 'translate(14px, -50%) scale(1)',  // Center the label vertically
                        transition: 'all 0.3s ease-out',
                    },
                    '& .MuiInputLabel-shrink': {
                        top: 0,
                        transform: 'translate(14px, -6px) scale(0.75)',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: '4px',  // Adjust margin as necessary
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '4px',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '14px',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    margin: '4px 0', // Top and bottom margin of 16px
                },
            },
        },
    },
});

export default theme;
