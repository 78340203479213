import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TablePagination,
    FormControlLabel, Switch
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {Domain} from "../models/domain";

interface DomainListProps {
    domains: Domain[];
    onEdit: (domain: Domain) => void;
    onDelete: (id: number) => void;
}

const DomainList: React.FC<DomainListProps> =
    ({ domains, onEdit, onDelete }) => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(5);

        const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, domains.length - page * rowsPerPage);

return (
    <Paper>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Root</TableCell>
                        <TableCell>Tag</TableCell>
                        <TableCell>Enabled</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {domains.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((domain) => (
                        <TableRow key={domain.id}>
                            <TableCell>{domain.id}</TableCell>
                            <TableCell>{domain.root}</TableCell>
                            <TableCell>{domain.tag}</TableCell>
                            <TableCell><FormControlLabel
                                control={<Switch checked={domain.enable} name="enable" />}
                                label=""
                            /></TableCell>
                            <TableCell>
                                <IconButton onClick={() => onEdit(domain)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => onDelete(domain.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={domains.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Paper>
    );
};

export default DomainList;
