import React from 'react';
import { TextField, FormGroup, FormControlLabel, Switch, Typography, MenuItem } from '@mui/material';

import {XtlsSettings} from "../../models/Inbound/StreamSetting/security/XtlsSettings";

interface XtlsSecurityFormProps {
    xtlsSettings: XtlsSettings;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const XtlsSecurityForm: React.FC<XtlsSecurityFormProps> = ({ xtlsSettings, handleChange }) => {
    const handleXtlsSettingsChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        console.log('change', e);
        handleChange(e);
    };

    return (
        <div>
            <Typography variant="h6"></Typography>
            <FormGroup>
                <TextField
                    label="Server Name"
                    name="streamSettings.xtlsSettings.serverName"
                    value={xtlsSettings.serverName}
                    onChange={handleXtlsSettingsChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="ALPN"
                    name="streamSettings.xtlsSettings.alpn"
                    value={xtlsSettings.alpn.join(', ')}
                    onChange={handleXtlsSettingsChange}
                    fullWidth
                    helperText="Enter ALPN values separated by commas"
                    margin="normal"
                />
                <FormControlLabel
                    control={<Switch name="streamSettings.xtlsSettings.settings.allowInsecure" checked={xtlsSettings.settings.allowInsecure} onChange={handleXtlsSettingsChange} />}
                    label="Allow Insecure"
                />
                {/*<TextField*/}
                {/*    label="Fingerprint"*/}
                {/*    name="settings.fingerprint"*/}
                {/*    select*/}
                {/*    value={xtlsSettings.settings.fingerprint}*/}
                {/*    onChange={handleXtlsSettingsChange}*/}
                {/*    fullWidth*/}
                {/*>*/}
                {/*    {Object.values(UTLS_FINGERPRINT).map(fingerprint => (*/}
                {/*        <MenuItem key={fingerprint} value={fingerprint}>*/}
                {/*            {fingerprint}*/}
                {/*        </MenuItem>*/}
                {/*    ))}*/}
                {/*</TextField>*/}
            </FormGroup>
        </div>
    );
};

export default XtlsSecurityForm;
