const seq = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export class RandomUtil {
    static randomIntRange(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min) + min);
    }

    static randomInt(n: number): number {
        return this.randomIntRange(0, n);
    }

    static randomSeq(count: number): string {
        let str = '';
        for (let i = 0; i < count; ++i) {
            str += seq[this.randomInt(62)];
        }
        return str;
    }

    static randomShortId(): string {
        let str = '';
        for (let i = 0; i < 8; ++i) {
            str += seq[this.randomInt(16)];
        }
        return str;
    }

    static randomLowerAndNum(len: number): string {
        let str = '';
        for (let i = 0; i < len; ++i) {
            str += seq[this.randomInt(36)];
        }
        return str;
    }

    static randomUUID(): string {
        const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
        return template.replace(/[xy]/g, (c) => {
            const randomValues = new Uint8Array(1);
            crypto.getRandomValues(randomValues);
            const randomValue = randomValues[0] % 16;
            const calculatedValue = (c === 'x') ? randomValue : (randomValue & 0x3 | 0x8);
            return calculatedValue.toString(16);
        });
    }

    static randomShadowsocksPassword(): string {
        const array = new Uint8Array(32);
        window.crypto.getRandomValues(array);
        return btoa(String.fromCharCode(...Array.from(array)));
    }
}

export class ObjectUtil {
    static getPropIgnoreCase(obj: Record<string, any>, prop: string): any {
        for (const name in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, name)) {
                if (name.toLowerCase() === prop.toLowerCase()) {
                    return obj[name];
                }
            }
        }
        return undefined;
    }

    static deepSearch(obj: any, key: string): boolean {
        if (obj instanceof Array) {
            for (let i = 0; i < obj.length; ++i) {
                if (this.deepSearch(obj[i], key)) {
                    return true;
                }
            }
        } else if (obj instanceof Object) {
            for (const name in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, name)) {
                    if (this.deepSearch(obj[name], key)) {
                        return true;
                    }
                }
            }
        } else {
            return this.isEmpty(obj) ? false : obj.toString().toLowerCase().indexOf(key.toLowerCase()) >= 0;
        }
        return false;
    }

    static isEmpty(obj: any): boolean {
        return obj === null || obj === undefined || obj === '';
    }

    static isArrEmpty(arr: any[]): boolean {
        return !this.isEmpty(arr) && arr.length === 0;
    }

    static copyArr<T>(dest: T[], src: T[]): void {
        dest.splice(0);
        for (const item of src) {
            dest.push(item);
        }
    }

    static clone<T extends Record<string, any>>(obj: T): T {
        let newObj: any;
        if (Array.isArray(obj)) {
            newObj = [];
            this.copyArr(newObj, obj);
        } else if (typeof obj === 'object' && obj !== null) {
            newObj = {} as T;
            for (const key of Object.keys(obj)) {
                (newObj as any)[key] = obj[key];
            }
        } else {
            newObj = obj;
        }
        return newObj;
    }

    static deepClone<T extends Record<string, any>>(obj: T): T {
        let newObj: any;
        if (Array.isArray(obj)) {
            newObj = [];
            for (const item of obj) {
                newObj.push(this.deepClone(item));
            }
        } else if (typeof obj === 'object' && obj !== null) {
            newObj = {} as T;
            for (const key of Object.keys(obj)) {
                (newObj as any)[key] = this.deepClone(obj[key]);
            }
        } else {
            newObj = obj;
        }
        return newObj;
    }

    static cloneProps<T extends Record<string, any>>(dest: T, src: T, ...ignoreProps: string[]): void {
        if (dest == null || src == null) {
            return;
        }
        const ignoreEmpty = this.isArrEmpty(ignoreProps);
        for (const key of Object.keys(src)) {
            if (!Object.prototype.hasOwnProperty.call(src, key)) {
                continue;
            } else if (!Object.prototype.hasOwnProperty.call(dest, key)) {
                continue;
            } else if (src[key] === undefined) {
                continue;
            }
            if (ignoreEmpty) {
                // @ts-ignore
                dest[key] = src[key];
            } else {
                let ignore = false;
                for (let i = 0; i < ignoreProps.length; ++i) {
                    if (key === ignoreProps[i]) {
                        ignore = true;
                        break;
                    }
                }
                if (!ignore) {
                    // @ts-ignore
                    dest[key] = src[key];
                }
            }
        }
    }

    static delProps(obj: Record<string, any>, ...props: string[]): void {
        for (const prop of props) {
            if (prop in obj) {
                delete obj[prop];
            }
        }
    }

    static execute(func: Function, ...args: any[]): void {
        if (!this.isEmpty(func) && typeof func === 'function') {
            func(...args);
        }
    }

    static orDefault<T>(obj: T, defaultValue: T): T {
        if (obj == null) {
            return defaultValue;
        }
        return obj;
    }

    static equals<T extends Record<string, any>>(a: T, b: T): boolean {
        for (const key in a) {
            if (!Object.prototype.hasOwnProperty.call(a, key)) {
                continue;
            }
            if (!Object.prototype.hasOwnProperty.call(b, key)) {
                return false;
            } else if (a[key] !== b[key]) {
                return false;
            }
        }
        return true;
    }
}

export class Wireguard {
    static gf(init?: number[]): Float64Array {
        var r = new Float64Array(16);
        if (init) {
            for (var i = 0; i < init.length; ++i)
                r[i] = init[i];
        }
        return r;
    }

    static pack(o: Uint8Array, n: Float64Array): void {
        var b, m = this.gf(), t = this.gf();
        for (var i = 0; i < 16; ++i)
            t[i] = n[i];
        this.carry(t);
        this.carry(t);
        this.carry(t);
        for (var j = 0; j < 2; ++j) {
            m[0] = t[0] - 0xffed;
            for (var i = 1; i < 15; ++i) {
                m[i] = t[i] - 0xffff - ((m[i - 1] >> 16) & 1);
                m[i - 1] &= 0xffff;
            }
            m[15] = t[15] - 0x7fff - ((m[14] >> 16) & 1);
            b = (m[15] >> 16) & 1;
            m[14] &= 0xffff;
            this.cswap(t, m, 1 - b);
        }
        for (var i = 0; i < 16; ++i) {
            o[2 * i] = t[i] & 0xff;
            o[2 * i + 1] = t[i] >> 8;
        }
    }

    static carry(o: Float64Array): void {
        var c;
        for (var i = 0; i < 16; ++i) {
            o[(i + 1) % 16] += (i < 15 ? 1 : 38) * Math.floor(o[i] / 65536);
            o[i] &= 0xffff;
        }
    }

    static cswap(p: Float64Array, q: Float64Array, b: number): void {
        var t, c = ~(b - 1);
        for (var i = 0; i < 16; ++i) {
            t = c & (p[i] ^ q[i]);
            p[i] ^= t;
            q[i] ^= t;
        }
    }

    static add(o: Float64Array, a: Float64Array, b: Float64Array): void {
        for (var i = 0; i < 16; ++i)
            o[i] = (a[i] + b[i]) | 0;
    }

    static subtract(o: Float64Array, a: Float64Array, b: Float64Array): void {
        for (let i = 0; i < 16; ++i)
            o[i] = (a[i] - b[i]) | 0;
    }

    static multmod(o: Float64Array, a: Float64Array, b: Float64Array): void {
        const t = new Float64Array(31);
        for (let i = 0; i < 16; ++i) {
            for (let j = 0; j < 16; ++j)
                t[i + j] += a[i] * b[j];
        }
        for (let i = 0; i < 15; ++i)
            t[i] += 38 * t[i + 16];
        for (let i = 0; i < 16; ++i)
            o[i] = t[i];
        this.carry(o);
        this.carry(o);
    }

    static invert(o: Float64Array, i: Float64Array): void {
        const c = this.gf();
        for (let a = 0; a < 16; ++a)
            c[a] = i[a];
        for (let a = 253; a >= 0; --a) {
            this.multmod(c, c, c);
            if (a !== 2 && a !== 4)
                this.multmod(c, c, i);
        }
        for (let a = 0; a < 16; ++a)
            o[a] = c[a];
    }

    static clamp(z: Uint8Array): void {
        z[31] = (z[31] & 127) | 64;
        z[0] &= 248;
    }

    static generatePublicKey(privateKey: Uint8Array): Uint8Array {
        const z = new Uint8Array(32);
        const a = this.gf([1]),
            b = this.gf([9]),
            c = this.gf(),
            d = this.gf([1]),
            e = this.gf(),
            f = this.gf(),
            _121665 = this.gf([0xdb41, 1]),
            _9 = this.gf([9]);
        for (let i = 0; i < 32; ++i)
            z[i] = privateKey[i];
        this.clamp(z);
        for (let i = 254; i >= 0; --i) {
            const r = (z[i >>> 3] >>> (i & 7)) & 1;
            this.cswap(a, b, r);
            this.cswap(c, d, r);
            this.add(e, a, c);
            this.subtract(a, a, c);
            this.add(c, b, d);
            this.subtract(b, b, d);
            this.multmod(d, e, e);
            this.multmod(f, a, a);
            this.multmod(a, c, a);
            this.multmod(c, b, e);
            this.add(e, a, c);
            this.subtract(a, a, c);
            this.multmod(b, a, a);
            this.subtract(c, d, f);
            this.multmod(a, c, _121665);
            this.add(a, a, d);
            this.multmod(c, c, a);
            this.multmod(a, d, f);
            this.multmod(d, b, _9);
            this.multmod(b, e, e);
            this.cswap(a, b, r);
            this.cswap(c, d, r);
        }
        this.invert(c, c);
        this.multmod(a, a, c);
        this.pack(z, a);
        return z;
    }

    static generatePresharedKey(): Uint8Array {
        const privateKey = new Uint8Array(32);
        window.crypto.getRandomValues(privateKey);
        return privateKey;
    }

    static generatePrivateKey(): Uint8Array {
        const privateKey = this.generatePresharedKey();
        this.clamp(privateKey);
        return privateKey;
    }
    static encodeBase64(dest: Uint8Array, src: Uint8Array): void {
        const input = Uint8Array.from([(src[0] >> 2) & 63, ((src[0] << 4) | (src[1] >> 4)) & 63, ((src[1] << 2) | (src[2] >> 6)) & 63, src[2] & 63]);
        for (let i = 0; i < 4; ++i)
            dest[i] = input[i] + 65 +
                (((25 - input[i]) >> 8) & 6) -
                (((51 - input[i]) >> 8) & 75) -
                (((61 - input[i]) >> 8) & 15) +
                (((62 - input[i]) >> 8) & 3);
    }

    static keyToBase64(key: Uint8Array): string {
        const base64 = new Uint8Array(44);
        let i;
        for (i = 0; i < 32 / 3; ++i)
            this.encodeBase64(base64.subarray(i * 4), new Uint8Array(key.subarray(i * 3)));
        this.encodeBase64(base64.subarray(i * 4), new Uint8Array([key[i * 3 + 0], key[i * 3 + 1], 0]));
        base64[43] = 61;
        // @ts-ignore
        return String.fromCharCode.apply(null, base64);
    }

    static keyFromBase64(encoded: string): Uint8Array {
        const binaryStr = atob(encoded);
        const bytes = new Uint8Array(binaryStr.length);
        for (let i = 0; i < binaryStr.length; i++) {
            bytes[i] = binaryStr.charCodeAt(i);
        }
        return bytes;
    }

    static generateKeypair(secretKey = ''): { publicKey: string, privateKey: string } {
        const privateKey = secretKey.length > 0 ? this.keyFromBase64(secretKey) : this.generatePrivateKey();
        const publicKey = this.generatePublicKey(privateKey);
        return {
            publicKey: this.keyToBase64(publicKey),
            privateKey: secretKey.length > 0 ? secretKey : this.keyToBase64(privateKey)
        };
    }
}