import {InboundDTO} from "../dto/Inbound";
import {Inbound,} from "./Inbound";
import {
    ALPN_OPTION, DOMAIN_STRATEGY_OPTION,
    NetworkType,
    ProtocolType,
    SecurityType,
    SSMethod, TCP_CONGESTION_OPTION,
    TLS_VERSION_OPTION,
    TransmissionType,
    USAGE_OPTION, UTLS_FINGERPRINT
} from "./Consts";


export function convertDTOToInbound(dto: InboundDTO): Inbound | undefined {
    if (!dto) {
        console.warn('Received undefined inboundDTO');
        return undefined;
    }

    const inboundData: Inbound = {
        id: dto.id,
        remark: dto.remark || '',
        enable: dto.enable || false,
        port: dto.port || 0,
        listen: dto.listen || '0.0.0.0',
        tag: dto.tag || '',
        protocol: dto.protocol,
        settings: JSON.parse(dto.settings),
        streamSettings: JSON.parse(dto.streamSettings),
        sniffing: JSON.parse(dto.sniffing),
    };
    return deepMerge({ ...initialInbound }, inboundData);
}

 const initialInbound: Inbound = {
    id: 0,
    remark: '',
    enable: true,
    port: 0,
    listen: '',
    tag: '',
    protocol: ProtocolType.VMESS,
    settings: {
        shadowsocks: {
            method: SSMethod.AES_256_GCM,
            password: '',
            network: NetworkType.TCP,
        },
        dokodemo: {
            address: '',
            port: 0,
            network: NetworkType.TCP,
            followRedirect: false,
            timeout: 0,
        },
        socks: {
            auth: "password",
            accounts: [{ user: '', pass: '' }],
            udp: false,
            ip: '',
        },
        http: {
            accounts: [{ user: '', pass: '' }],
        },
        wireguard: {
            mtu: 1420,
            secretKey: '',
            pubKey: '',
            peers: [{
                privateKey: '',
                publicKey: '',
                allowedIPs: [''],
                keepAlive: 0,
            }],
            kernelMode: false,
        },
    },
    streamSettings: {
        network: TransmissionType.Tcp,
        security: SecurityType.None,
        tlsSettings: {
            serverName: '',
            minVersion: TLS_VERSION_OPTION.TLS10,
            maxVersion: TLS_VERSION_OPTION.TLS13,
            cipherSuites: '',
            rejectUnknownSni: false,
            disableSystemRoot: false,
            enableSessionResumption: false,
            certificates: [{
                useFile: false,
                certificateFile: '',
                keyFile: '',
                certificate: '',
                key: '',
                ocspStapling: 0,
                oneTimeLoading: false,
                usage: USAGE_OPTION.ENCIPHERMENT,
            }],
            alpn: [ALPN_OPTION.H3],
            settings: {
                allowInsecure: false,
                fingerprint: '',
            },
        },
        xtlsSettings: {
            serverName: '',
            certificates: [{
                useFile: false,
                certificateFile: '',
                keyFile: '',
                certificate: '',
                key: '',
                ocspStapling: 0,
                oneTimeLoading: false,
                usage: USAGE_OPTION.ENCIPHERMENT,
            }],
            alpn: [ALPN_OPTION.H3],
            settings: {
                allowInsecure: false,
            },
        },
        realitySettings: {
            show: false,
            xver: 0,
            dest: '',
            serverNames: [''],
            privateKey: '',
            minClient: '',
            maxClient: '',
            maxTimediff: 0,
            shortIds: [''],
            settings: {
                publicKey: '',
                fingerprint: UTLS_FINGERPRINT.UTLS_CHROME,
                serverName: '',
                spiderX: '',
            },
        },
        tcpSettings: {
            acceptProxyProtocol: false,
            type: 'none',
            // request: {
            //     version: '',
            //     method: '',
            //     path: [''],
            //     headers: [{ name: '', value: '' }],
            // },
            // response: {
            //     version: '',
            //     status: '',
            //     reason: '',
            //     headers: [{ name: '', value: '' }],
            // },
        },
        kcpSettings: {
            mtu: 1350,
            tti: 50,
            uplinkCapacity: 5,
            downlinkCapacity: 20,
            congestion: false,
            readBufferSize: 2,
            writeBufferSize: 2,
            type: '',
            seed: '',
        },
        wsSettings: {
            acceptProxyProtocol: false,
            path: '',
            host: '',
            headers: [{ name: '', value: '' }],
        },
        httpSettings: {
            path: '',
            host: [''],
        },
        quicSettings: {
            security: '',
            key: '',
            type: '',
        },
        grpcSettings: {
            serviceName: '',
            authority: '',
            multiMode: false,
        },
        httpUpgradeSettings: {
            acceptProxyProtocol: false,
            path: '',
            host: '',
            headers: [{ name: '', value: '' }],
        },
        sockopt: {
            enable: false,
            mark: 0,
            tproxy: '',
            tcpMptcp: false,
            tcpNoDelay: false,
            domainStrategy: DOMAIN_STRATEGY_OPTION.AS_IS,
            tcpMaxSeg: 0,
            dialerProxy: '',
            tcpKeepAliveInterval: 0,
            tcpKeepAliveIdle: 0,
            tcpUserTimeout: 0,
            tcpcongestion: TCP_CONGESTION_OPTION.BBR,
            V6Only: false,
            tcpWindowClamp: 0,
            interface: '',
        },
    },
    sniffing: {
        enabled: false,
        destOverride: [],
        metadataOnly: false,
        routeOnly: false,
    },

};

const deepMerge = (target: any, source: any) => {
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object && !(source[key] instanceof Array)) {
            if (!target[key]) {
                target[key] = {};
            }
            deepMerge(target[key], source[key]);
        } else {
            target[key] = source[key];
        }
    }
    return target;
};
