import React, {useEffect, useState} from 'react';
import {TextField, Button, Box, Switch, FormControlLabel, Divider} from '@mui/material';
import {Inbound, InboundHandler, updateNestedValue} from '../models/Inbound/Inbound'; // Adjust the import path as needed
import ProtocolForm from './protocol/ProtocolForm';
import SniffingForm from './SniffingForm';
import NetworkForm from "./network/NetworkForm";
import SecurityForm from "./security/SecurityForm";
import SockoptForm from "./SockoptForm";
import {InboundDTO} from "../models/dto/Inbound";
import {convertInboundToDTO} from "../models/Inbound/ConvertInboundToDTO";
import {
    ALPN_OPTION, DOMAIN_STRATEGY_OPTION,
    NetworkType,
    ProtocolType,
    SecurityType,
    SSMethod, TCP_CONGESTION_OPTION,
    TLS_VERSION_OPTION,
    TransmissionType, USAGE_OPTION, UTLS_FINGERPRINT
} from "../models/Inbound/Consts";
import InboundService from "../services/InboundService";
import {RandomUtil} from "../js/util/utils";

interface InboundFormProps {
    inboundProp?: Inbound;
    onSubmit: (inboundDTO: InboundDTO) => void;
    isEditing: boolean;
}

const InboundForm: React.FC<InboundFormProps> = ({inboundProp, onSubmit, isEditing}) => {

    const [inbound, setInbound] = useState<Inbound>
    (inboundProp || {
        id: 0,
        remark: '',
        enable: true,
        port: RandomUtil.randomIntRange(1025, 65500),
        listen: '0.0.0.0',
        tag: '',
        protocol: ProtocolType.VMESS,
        settings: {
            shadowsocks: {
                method: SSMethod.AES_256_GCM,
                password: '',
                network: NetworkType.TCP_UDP,
            },
            dokodemo: {
                address: '',
                port: 0,
                network: NetworkType.TCP_UDP,
                followRedirect: false,
                timeout: 0,
            },
            socks: {
                auth: "password",
                accounts: [{user: '', pass: ''}],
                udp: false,
                ip: '',
            },
            http: {
                accounts: [{user: '', pass: ''}],
            },
            wireguard: {
                mtu: 1420,
                secretKey: '',
                pubKey: '',
                peers: [{
                    privateKey: '',
                    publicKey: '',
                    allowedIPs: [''],
                    keepAlive: 0,
                }],
                kernelMode: false,
            },
        },
        streamSettings: {
            network: TransmissionType.Tcp,
            security: SecurityType.None,
            tlsSettings: {
                serverName: '',
                minVersion: TLS_VERSION_OPTION.TLS10,
                maxVersion: TLS_VERSION_OPTION.TLS13,
                cipherSuites: '',
                rejectUnknownSni: false,
                disableSystemRoot: false,
                enableSessionResumption: false,
                certificates: [{
                    useFile: false,
                    certificateFile: '',
                    keyFile: '',
                    certificate: '',
                    key: '',
                    ocspStapling: 0,
                    oneTimeLoading: false,
                    usage: USAGE_OPTION.ENCIPHERMENT,
                }],
                alpn: [ALPN_OPTION.H3],
                settings: {
                    allowInsecure: false,
                    fingerprint: '',
                },
            },
            xtlsSettings: {
                serverName: '',
                certificates: [{
                    useFile: false,
                    certificateFile: '',
                    keyFile: '',
                    certificate: '',
                    key: '',
                    ocspStapling: 0,
                    oneTimeLoading: false,
                    usage: USAGE_OPTION.ENCIPHERMENT,
                }],
                alpn: [ALPN_OPTION.H3],
                settings: {
                    allowInsecure: false,
                },
            },
            realitySettings: {
                show: false,
                xver: 0,
                dest: '',
                serverNames: [''],
                privateKey: '',
                minClient: '',
                maxClient: '',
                maxTimediff: 0,
                shortIds: [''],
                settings: {
                    publicKey: '',
                    fingerprint: UTLS_FINGERPRINT.UTLS_CHROME,
                    serverName: '',
                    spiderX: '',
                },
            },
            tcpSettings: {
                acceptProxyProtocol: false,
                type: 'none',
                // request: {
                //     version: '',
                //     method: '',
                //     path: [''],
                //     headers: [{name: '', value: ''}],
                // },
                // response: {
                //     version: '',
                //     status: '',
                //     reason: '',
                //     headers: [{name: '', value: ''}],
                // },
            },
            kcpSettings: {
                mtu: 1350,
                tti: 50,
                uplinkCapacity: 5,
                downlinkCapacity: 20,
                congestion: false,
                readBufferSize: 2,
                writeBufferSize: 2,
                type: '',
                seed: '',
            },
            wsSettings: {
                acceptProxyProtocol: false,
                path: '',
                host: '',
                headers: [{name: '', value: ''}],
            },
            httpSettings: {
                path: '',
                host: [''],
            },
            quicSettings: {
                security: '',
                key: '',
                type: '',
            },
            grpcSettings: {
                serviceName: '',
                authority: '',
                multiMode: false,
            },
            httpUpgradeSettings: {
                acceptProxyProtocol: false,
                path: '',
                host: '',
                headers: [{name: '', value: ''}],
            },
            sockopt: {
                // acceptProxyProtocol: false,
                // tcpFastOpen: false,
                enable: false,
                mark: 0,
                tproxy: '',
                tcpMptcp: false,
                tcpNoDelay: false,
                domainStrategy: DOMAIN_STRATEGY_OPTION.AS_IS,
                tcpMaxSeg: 0,
                dialerProxy: '',
                tcpKeepAliveInterval: 0,
                tcpKeepAliveIdle: 0,
                tcpUserTimeout: 0,
                tcpcongestion: TCP_CONGESTION_OPTION.BBR,
                V6Only: false,
                tcpWindowClamp: 0,
                interface: '',
            },
        },
        sniffing: {
            enabled: false,
            destOverride: [],
            metadataOnly: false,
            routeOnly: false,
        },

    });
    const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
    const [portError, setPortError] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        const {name, value, type, checked} = e.target as HTMLInputElement;
        const path = name.split('.');
        const newValue = type === 'checkbox' || type === 'switch' ? checked : value;
        const newFormData = {...inbound};
        updateNestedValue(newFormData, path, newValue);
        setInbound(newFormData);
    };


    const handleChangePort = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        setPortError(null); // Reset error
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        setDebounceTimer(setTimeout(() => validatePort(e.target.value as number), 500));

        handleChange(e,)
    };

    const validatePort = async (port: number) => {
        try {
            const isDuplicate = await InboundService.existByPort(port);
            if (isDuplicate) {
                setPortError('Port is already taken.');
            } else {
                setPortError(null);
            }
        } catch (error) {
            console.error('Failed to validate port', error);
        }
        if (port < 1 || port > 65500) {
            setPortError('Port must be between 1 and 65531');
        } else {
            setPortError(null);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!portError) {
            onSubmit(convertInboundToDTO(inbound));
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '300px', // Adjust as necessary to double the size
            boxSizing: 'border-box'
        }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={inbound.enable}
                        onChange={(e) => handleChange({
                            ...e,
                            target: {...e.target, name: 'enable', value: e.target.checked}
                        })}
                        name="enable"
                    />
                }
                label="Enable"
            />

            <TextField
                label="Listen IP"
                name="listen"
                value={inbound.listen}
                onChange={handleChange}
                fullWidth
            />

            <TextField
                label="Port"
                name="port"
                type="number"
                value={inbound.port}
                onChange={handleChangePort}
                fullWidth
                error={!!portError}
                helperText={portError}
                inputProps={{ min: 1, max: 65531 }}
                disabled={isEditing}
            />


            <Divider/>
            <ProtocolForm protocol={inbound.protocol} settings={inbound.settings} handleChange={handleChange} isEditing={isEditing}/>
            <Divider/>
            {InboundHandler.canEnableStream(inbound.protocol) && (
                <>
                    <NetworkForm streamSettings={inbound.streamSettings} handleChange={handleChange}/>
                    <Divider/>
                    <SecurityForm streamSettings={inbound.streamSettings} handleChange={handleChange}/>
                    <Divider/>
                    <SockoptForm sockopt={inbound.streamSettings.sockopt!} handleChange={handleChange}/>
                    <Divider/>
                </>
            )}

            <SniffingForm sniffing={inbound.sniffing} handleChange={handleChange}/>

            <Button type="submit" variant="contained"
                    color="primary">{inboundProp ? 'Update Inbound' : 'Add Inbound'}</Button>
        </Box>
    );
};

export default InboundForm;

