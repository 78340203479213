import React from 'react';
import { TextField, MenuItem, Box } from '@mui/material';
import {StreamSetting} from '../../models/Inbound/Inbound'; // Adjust the import path as needed
import TcpSettingsForm from './TcpSettingsForm';
import KcpSettingsForm from './KcpSettingsForm';
import WsSettingsForm from './WsSettingsForm';
import HttpSettingsForm from './HttpSettingsForm';
import QuicSettingsForm from './QuicSettingsForm';
import GrpcSettingsForm from './GrpcSettingsForm';
import HTTPUpgradeSettingsForm from './HTTPUpgradeSettingsForm';
import {TransmissionType} from "../../models/Inbound/Consts";

interface NetworkFormProps {
    streamSettings: StreamSetting;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const NetworkForm: React.FC<NetworkFormProps> = ({ streamSettings, handleChange }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <TextField
                select
                label="Transmission Type"
                name="streamSettings.network"
                value={streamSettings.network}
                onChange={handleChange}
                fullWidth
            >
                {Object.values(TransmissionType).map((type) => (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                ))}
            </TextField>
            {streamSettings.network === TransmissionType.Tcp && (
                <TcpSettingsForm tcpSettings={streamSettings.tcpSettings!} handleChange={handleChange} />
            )}
            {streamSettings.network === TransmissionType.Kcp && (
                <KcpSettingsForm kcpSetting={streamSettings.kcpSettings!} handleChange={handleChange} />
            )}
            {streamSettings.network === TransmissionType.Ws && (
                <WsSettingsForm wsSettings={streamSettings.wsSettings!} handleChange={handleChange} />
            )}
            {streamSettings.network === TransmissionType.HTTP && (
                <HttpSettingsForm httpSettings={streamSettings.httpSettings!} handleChange={handleChange} />
            )}
            {streamSettings.network === TransmissionType.Quic && (
                <QuicSettingsForm quicSettings={streamSettings.quicSettings!} handleChange={handleChange} />
            )}
            {streamSettings.network === TransmissionType.Grpc && (
                <GrpcSettingsForm grpcSettings={streamSettings.grpcSettings!} handleChange={handleChange} />
            )}
            {streamSettings.network  === TransmissionType.HTTPUpgrade && (
                <HTTPUpgradeSettingsForm httpUpgradeSettings={streamSettings.httpUpgradeSettings!} handleChange={handleChange} />
            )}
        </Box>
    );
};

export default NetworkForm;
