import React from 'react';
import { TextField, FormControlLabel, Switch, MenuItem, Box } from '@mui/material';

import {Sockopt} from "../models/Inbound/StreamSetting/Sockopt";
import {DOMAIN_STRATEGY_OPTION, TCP_CONGESTION_OPTION} from "../models/Inbound/Consts"; // Adjust the import path as needed

interface SockoptFormProps {
    sockopt: Sockopt;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
}

const SockoptForm: React.FC<SockoptFormProps> = ({ sockopt, handleChange }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>

            {/*<FormControlLabel*/}
            {/*    control={*/}
            {/*        <Switch*/}
            {/*            checked={sockopt.tcpFastOpen}*/}
            {/*            onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'streamSettings.sockopt.tcpFastOpen', value: e.target.checked } })}*/}
            {/*            name="streamSettings.sockopt.tcpFastOpen"*/}
            {/*        />*/}
            {/*    }*/}
            {/*    label="TCP Fast Open"*/}
            {/*/>*/}
                <FormControlLabel
                    control={
                            <Switch
                                checked={sockopt.enable}
                                onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'streamSettings.sockopt.enable', value: e.target.checked } })}
                                name="streamSettings.sockopt.enable"
                            />
                    }
                    label="Sockopt"
                />
            {sockopt.enable && (
                <>
            <TextField
                label="Mark"
                name="streamSettings.sockopt.mark"
                type="number"
                value={sockopt.mark}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="TProxy"
                name="streamSettings.sockopt.tproxy"
                value={sockopt.tproxy}
                onChange={handleChange}
                fullWidth
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={sockopt.tcpMptcp}
                        onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'streamSettings.sockopt.tcpMptcp', value: e.target.checked } })}
                        name="streamSettings.sockopt.tcpMptcp"
                    />
                }
                label="TCP MPTCP"
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={sockopt.tcpNoDelay}
                        onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'streamSettings.sockopt.tcpNoDelay', value: e.target.checked } })}
                        name="streamSettings.sockopt.tcpNoDelay"
                    />
                }
                label="TCP No Delay"
            />
            <TextField
                select
                label="Domain Strategy"
                name="streamSettings.sockopt.domainStrategy"
                value={sockopt.domainStrategy}
                onChange={handleChange}
                fullWidth
            >
                {Object.values(DOMAIN_STRATEGY_OPTION).map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                label="TCP Max Segment"
                name="streamSettings.sockopt.tcpMaxSeg"
                type="number"
                value={sockopt.tcpMaxSeg}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="Dialer Proxy"
                name="sockopt.dialerProxy"
                value={sockopt.dialerProxy}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="TCP Keep Alive Interval"
                name="streamSettings.sockopt.tcpKeepAliveInterval"
                type="number"
                value={sockopt.tcpKeepAliveInterval}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="TCP Keep Alive Idle"
                name="streamSettings.sockopt.tcpKeepAliveIdle"
                type="number"
                value={sockopt.tcpKeepAliveIdle}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="TCP User Timeout"
                name="streamSettings.sockopt.tcpUserTimeout"
                type="number"
                value={sockopt.tcpUserTimeout}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                select
                label="TCP Congestion"
                name="streamSettings.sockopt.tcpcongestion"
                value={sockopt.tcpcongestion}
                onChange={handleChange}
                fullWidth
            >
                {Object.values(TCP_CONGESTION_OPTION).map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <FormControlLabel
                control={
                    <Switch
                        checked={sockopt.V6Only}
                        onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'streamSettings.sockopt.V6Only', value: e.target.checked } })}
                        name="streamSettings.sockopt.V6Only"
                    />
                }
                label="IPv6 Only"
            />
            <TextField
                label="TCP Window Clamp"
                name="streamSettings.sockopt.tcpWindowClamp"
                type="number"
                value={sockopt.tcpWindowClamp}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="Interface"
                name="streamSettings.sockopt.interface"
                value={sockopt.interface}
                onChange={handleChange}
                fullWidth
            />  </>
            )}
        </Box>
    );
};

export default SockoptForm;
