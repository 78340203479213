import React from 'react';
import {TextField, MenuItem, Box, FormControlLabel, Switch} from '@mui/material';
import { Settings } from '../../models/Inbound/Inbound'; // Adjust the import path as needed
import ShadowsocksForm from './ShadowsocksForm';
import SocksForm from './SocksForm';
import HttpForm from './HttpForm';
import WireguardForm from './WireguardForm';
import {ProtocolType} from "../../models/Inbound/Consts";

interface ProtocolFormProps {
    protocol: ProtocolType;
    settings: Settings;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void;
    isEditing: boolean;
}

const ProtocolForm: React.FC<ProtocolFormProps> = ({ protocol,settings , handleChange , isEditing}) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
                select
                label="Protocol"
                name="protocol"
                value={protocol}
                onChange={handleChange}
                fullWidth
                disabled={isEditing}
            >
                {Object.values(ProtocolType).map((type) => (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                ))}
            </TextField>
            {protocol === ProtocolType.SHADOWSOCKS && (
                <ShadowsocksForm shadowsocks={settings.shadowsocks} handleChange={handleChange} />
            )}
            {/*{protocol === ProtocolType.DOKODEMO && (*/}
            {/*    <DokodemoForm dokodemo={settings.dokodemo} handleChange={handleChange} />*/}
            {/*)}*/}
            {/*{protocol === ProtocolType.SOCKS && (*/}
            {/*    <SocksForm socks={settings.socks} handleChange={handleChange} />*/}
            {/*)}*/}
            {/*{protocol === ProtocolType.HTTP && (*/}
            {/*    <HttpForm http={settings.http} handleChange={handleChange} />*/}
            {/*)}*/}
            {/*{protocol === ProtocolType.WIREGUARD && (*/}
            {/*    <WireguardForm wireguard={settings.wireguard} handleChange={handleChange} />*/}
            {/*)}*/}


        </Box>
    );
};

export default ProtocolForm;
