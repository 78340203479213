// InboundServerManagementPage.tsx
import React, {useState, useEffect} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Button, Paper, Typography, Box, FormControlLabel, Switch, Popover
} from '@mui/material';
import axios from 'axios';
import {getServers} from '../services/serverService';
import {Server} from "../models/server";
import {useNavigate, useParams} from "react-router-dom";
import {updateInboundToServers, getSelectedServerIds} from "../services/InboundService";
import {Country, countryFlagMap} from "../models/Country";

interface ServerWithStatus extends Server {
    status?: string;
    message?: string;
}

const InboundServersManagementPage: React.FC = () => {
    const [servers, setServers] = useState<ServerWithStatus[]>([]);
    const [selectedServerIds, setSelectedServerIds] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [saveStatus, setSaveStatus] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const {inboundId} = useParams<{ inboundId: string }>();
    const numericInboundId = Number(inboundId); // Convert to number
    const navigate = useNavigate();


    useEffect(() => {
        fetchServers();
        fetchSelectedServers();
    }, []);

    const fetchServers = async () => {
        try {
            const response = await getServers();
            setServers(response.data);
        } catch (error) {
            console.error('Failed to fetch servers:', error);
        }
    };

    const fetchSelectedServers = async () => {
        try {
            const response = await getSelectedServerIds(numericInboundId);
            setSelectedServerIds(response || []);
        } catch (error) {
            console.error('Failed to fetch selected servers:', error);
            setSelectedServerIds([]);
        }
    };


    const handleCheckboxChange = (serverId: number) => {
        setSelectedServerIds(prevState =>
            prevState.includes(serverId)
                ? prevState.filter(id => id !== serverId)
                : [...prevState, serverId]
        );
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedServerIds([]);
        } else {
            const allServerIds = servers.map(server => server.id);
            setSelectedServerIds(allServerIds);
        }
        setSelectAll(!selectAll);
    };

    const handleSave = async () => {
        try {
            const result = await updateInboundToServers(numericInboundId, selectedServerIds);
            const updatedServers = servers.map(server => {
                const resultForServer = result.results.find(r => r.serverId === server.id);
                return {
                    ...server,
                    status: resultForServer?.status,
                    message: resultForServer?.message
                };
            });
            setServers(updatedServers);

            const allSucceeded = result.results.every(r => r.status !== 'Failed');
            if (allSucceeded) {
                setSaveStatus('Success');
                setTimeout(() => {
                    setSaveStatus(null); // Reset save status after success
                    navigate(-1); // Navigate back after 2 seconds
                }, 2000);
            } else {
                setSaveStatus('PartialSuccess');
            }
        } catch (error) {
            console.error('Failed to save inbound servers:', error);
            setSaveStatus('Failed');
            // Handle save error
            const updatedServers = servers.map(server => ({
                ...server,
                status: 'Failed',
                message: 'Failed to save changes'
            }));
            setServers(updatedServers);
        }
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Manage Servers for Inbound {inboundId}
            </Typography>
            <Box m={2} display="flex" justifyContent="flex-end">
                <Button onClick={handleSave} variant="contained" color="primary">
                    Save
                </Button>
            </Box>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                        indeterminate={
                                            selectedServerIds.length > 0 &&
                                            selectedServerIds.length < servers.length
                                        }
                                    />
                                </TableCell>
                                <TableCell>Country</TableCell>
                                <TableCell>Server IP</TableCell>
                                <TableCell>Tag</TableCell>
                                <TableCell>Enabled</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {servers.map(server => (
                                <TableRow key={server.id}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedServerIds.includes(server.id)}
                                            onChange={() => handleCheckboxChange(server.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {countryFlagMap[server.country as Country]} {server.country}
                                    </TableCell>
                                    <TableCell>{server.ip}</TableCell>
                                    <TableCell>{server.tag}</TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={<Switch checked={server.enable}/>}
                                            label={""}
                                        />
                                    </TableCell>
                                    <TableCell>{server.status}</TableCell>
                                    <TableCell
                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseLeave={handlePopoverClose}
                                        onClick={handlePopoverOpen}
                                        sx={{
                                            maxWidth: '100px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            m: 2,
                                        }}>

                                        {server.message}

                                        <Popover
                                            id="mouse-over-popover"
                                            sx={{
                                                pointerEvents: 'none',
                                            }}
                                            open={open}

                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                        >
                                            <Typography sx={{p: 1, maxWidth: '300px',}}>{server.message}</Typography>
                                        </Popover>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

export default InboundServersManagementPage;
