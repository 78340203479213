import {Client} from "../models/client";
import axiosInstance from '../utils/axiosConfig';

const API_URL =  '/clients';

export const getClients = () => axiosInstance.get<Client[]>(API_URL);

export const createClient = (client: Client) => axiosInstance.post(API_URL, client);

export const updateClient = (id: number, client: Client) => axiosInstance.put(`${API_URL}/${id}`, client);

export const deleteClient = (id: number) => axiosInstance.delete(`${API_URL}/${id}`);

export const existByEmail = async (email: string): Promise<boolean> => {
    try {
        const response = await axiosInstance.get(`${API_URL}/validate-email`, { params: { email } });
        return response.data; // Assuming the server returns true or false
    } catch (error) {
        console.error('Failed to validate email', error);
        throw error;
    }
};
export const renewClient = (id: number, newDataCap: number, renewalPeriodMonths: number) => {
    return axiosInstance.post<Client>(`${API_URL}/${id}/renew`, null, {
        params: {
            newDataCap,
            renewalPeriodMonths
        }
    });
};